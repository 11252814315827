document.addEventListener('DOMContentLoaded', function() {
    $.fn.serializeObject = function() {
        var o = {};
        var d = $(this).find('[disabled]');
        d.each(function() {$(this).removeAttr('disabled')});
        var a = this.serializeArray();
        d.each(function() {$(this).attr('disabled', 'disabled')});

        $.each(a, function() {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };
});
