(function() {
    var $footer = $('footer.footer');

    if (!$footer.length) {
        return false;
    }

    var counter = 0;
    var sequence = 500;
    var interval = setInterval(footerToBottom, sequence);

    function footerToBottom() {
        counter += sequence;

        if (counter >= 5000) {
            clearInterval(interval);
        }

        var docHeight = $(window).height();
        var footerHeight = $footer.height();
        var footerTop = $footer.position().top + footerHeight;

        if (footerTop < docHeight) {
            $footer.css({
                'margin-top': (docHeight - footerTop) + 'px'
            });
        } else {
            $footer.css({
                'margin-top': ''
            });
        }
    }
}());
