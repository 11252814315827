/**
 * matchgameService.js
 * Handles the functionality for the matchgame
 */

/**
 *
 * @type {{getInstance}}
 */

window.matchgameService = (function () {
    var instance;

    function init() {
        'use strict';

        if (!window.template) {
            return false;
        }

        var postData = {};
        var results = [];
        var tmpl = window.Hogan.compile(window.template);
        var popularCycle = false;
        var html = '';
        var offset = 0;
        var limit = 25;
        var current = 0;
        var total = 0;
        var processing = false;
        var forceCount = false;
        var $matchgameModal = $('[data-cnt="search-result"]');
        var matchgameEnd = false;

        var displayRegion = window.application.options.customSettings.geo.displayRegion;
        var displayCity = window.application.options.customSettings.geo.displayCity;

        var updatedSearchPref = false;

        var $body = $('body');


        //functions to handle the search calls
        //---------------------------------------------------------------

        function initialSearch() {
            showLoadingSpinner();

            window.application.callAjax('account', 'getSearch').then(
                function (preferences) {
                    postData = {
                        conditions: {
                            eq: {
                                gender: preferences.gender,
                                hasPhoto: 1
                            },
                            gte: {
                                age: preferences.minAge
                            },
                            lte: {
                                age: preferences.maxAge
                            }
                        },
                        filter: ['match.excludeLiked', 'match.excludeDisliked'],
                        includePhotos: 1
                    };

                    //below is done and MUST be done seperately in order to support IE11
                    postData.conditions.eq[displayRegion] = preferences[displayRegion];

                    countProfiles(postData, offset);
                }
            );
        }

        function countProfiles() {
            postData.offset = offset;

            window.application.callAjax(
                'profile',
                'count',
                postData,
                true
            ).then(
                function(count) {
                    loadProfiles(count);
                }
            );
        }

        function loadProfiles(count) {
            postData.limit = limit;
            window.application.callAjax(
                'profile',
                'search',
                postData,
                true
            ).then(
                function(response) {
                    total   = count;
                    results = response;

                    //put results in the renderprofile function.
                    if (results.length > 0) {
                        renderProfile(response[current], total);
                    } else {
                        //if you are in the popular cycle end the matchgame else show popular profiles
                        if (popularCycle) {
                            removeLoadingSpinner();
                            endMatchgame();
                        } else {
                            showPopular();
                        }
                    }
                }
            );
        }

        function showPopular(){
            postData = {
                conditions : {
                    eq : {
                        gender      : postData.conditions.eq.gender,
                        hasPhoto    : 1
                    },
                    gte : {
                        age : 18
                    },
                    lte : {
                        age : 99
                    }
                },
                filter : ['match.excludeLiked', 'match.excludeDisliked'],
                includePhotos : 1,
                offset: 0
            };
            popularCycle = true;
            countProfiles();
        }

        //functions to render the templates
        //---------------------------------------------------------------

        function renderProfile(profile) {
            var mapping = window.application.options.valueMapping;

            var splash = profile.media ? profile.media.find(function (photo) {
                return photo.isSplash;
            }) : undefined;

            if (splash) {
                profile.splashPhoto = splash.resolutions.medium.unblurred;
                profile.splashPhotoLarge = splash.resolutions.large.unblurred;
            }

            profile = profile || {};
            profile.photos       = profile.photos || [];
            profile.lastActivity = profile.lastActivity || null;
            profile.created      = profile.created || null;
            profile.winkMessages = window.application.options.winkMessages;

            for (var i in profile) {
                if (mapping[i]) {
                    profile[i] = mapping[i][profile[i]];
                }
            }

            if (offset === total) {
                return;
            }

            if (current === limit) {
                current = 0;
                offset  = 0;

                if (forceCount === true) {
                    countProfiles(postData, offset);
                    return;
                }

                initialSearch(postData, offset);
                return;
            } else {
                current++;
            }

            offset++;

            if (profile.region === 'Limburg_be') {
                profile.region = 'Limburg (be)';
            }

            if (profile.lastActivity) {
                profile.lastActivity = window.application.getDate(window.application.convertDate(profile.lastActivity.date));
            }

            if (profile.created) {
                profile.created = window.application.getDate(window.application.convertDate(profile.created.date));
            }

            html = tmpl.render(profile);

            removeLoadingSpinner();

            $matchgameModal.append(html);

            //check the length of result and substract the current profile number of the array your in
            $('[data-content="match-result-count"]').text((total + 1) - current);

            //if its bigger than 1 show some other text
            if ((results.length + 1) - current > 1) {
                $('[data-text="single-result"]').hide();
                $('[data-text="multiple-results"]').show();
            } else {
                $('[data-text="multiple-results"]').hide();
                $('[data-text="single-result"]').show();
            }

            $('[data-profileimg]').attr('src', '/content/' + window.folder + '/' + window.profileId + '/splash.jpg');

            matchgameModalGoesIn();

            $('[data-profilename]', '[data-firsttime]').text(profile.clientname);

            window.application.svgCheck();

            if (popularCycle) {
                $('[data-content="result-text"]').hide();
                $('[data-content="popular-text"]').show();
            }
        }

        //functions to handle the animations and states of the matchgame
        //---------------------------------------------------------------

        function matchgameModalGoesIn() {
            $matchgameModal
                .css('left', '-100%')
                .show()
                .animate({
                    left: '0'
                }, 400, 'linear', function () {
                    removeLoadingSpinner();
                });
        }

        function matchgameModalGoesOut() {
            $matchgameModal
                .animate({
                    left: '100%'
                }, 400, 'linear', function() {
                    showLoadingSpinner();
                });
        }

        function showLoadingSpinner() {
            var tpl = '<i class="matchgame-spinner fa fa-spinner fa-pulse fa-3x fa-fw" data-selector="matchgame-spinner"></i>';

            $(document.body).append(tpl);
        }

        function removeLoadingSpinner() {
            $('[data-selector="matchgame-spinner"]', 'body').remove();
        }

        function gotoNext() {
            //if you updated your search preferences do reset the matchgame with new preferences,
            // if you reached the result.length (the end of current profiles) reset the search to popularcycle
            // else render next result
            if (updatedSearchPref) {
                $('[data-cnt="search-result"]').fadeOut(function() {
                    $('[data-selector="matchgame-end"]').hide();
                    $('[data-cnt="search"]').show();
                    $('[data-link="matchgame-settings"]').show();
                    $('[data-content="matchgame-buttons"]').show();
                    popularCycle = false;
                    offset = 0;
                    current = 0;
                    total = 0;
                    updatedSearchPref = false;
                    $(this).html('');
                    initialSearch();
                });
            } else if (offset === results.length) {
                $('[data-cnt="search-result"]').fadeOut(function() {
                    offset = 0;
                    current = 0;
                    total = 0;
                    $(this).html('');
                    initialSearch();
                });
            }

            $('[data-cnt="search-result"]').fadeOut(function() {
                $(this).html('');
                renderProfile(results[current]);
                $('[data-cnt="search"]').show();
                $('[data-content="matchgame-buttons"]').show();
            });
        }

        function showMatched() {
            $('[data-content="popular-text"]').hide();
            $('[data-content="matchgame-buttons"]').hide();
            $('[data-link="matchgame-settings"]').hide();
            $('[data-content="matchgame-matched-buttons"]').show();
            $('[data-content="matched"]').fadeIn();


            //calculate current height of image and based on that give a margin top
            var imageHeight = $('[data-image="matchgame-image"]').height();
            var matchedHeight = $('[data-content="matched-wrapper"]').height();
            var neededMargin = (imageHeight - matchedHeight) / 2;

            $('[data-content="matched-wrapper"]').css( 'margin-top', neededMargin + 'px');

            removeLoadingSpinner();

            matchgameModalGoesIn();

            // on close, go to next profile
            $('[data-selector="close-matched"]').click(function () {
                $('[data-content="matched"]').fadeOut(function () {
                    gotoNext();
                });
            });
        }

        function endMatchgame() {
            matchgameEnd = true;
            $('[data-selector="matchgame-results"]').hide();
            $('[data-selector="matchgame-end"]').show();
        }

        function potentialMatch(id) {
            window.application.callApi('dating', 'match', 'potentialMatch', {id: id}).then(
                function(response) {
                    if (response.match === false) {
                        gotoNext();
                    } else {
                        showMatched();
                    }
                }
            );
        }

        //functions to like dislike profiles
        //---------------------------------------------------------------

        function likeProfile(id) {
            if (!processing) {
                processing = true;

                window.application.callApi('dating', 'match', 'like', {id: id}).then(
                    function (response) {
                        if (response.match === false) {
                            potentialMatch(id);
                        } else {
                            showMatched();
                        }
                        processing = false;
                    },
                    function () {
                        processing = false;
                    }
                );
            }
        }

        function dislikeProfile(id) {
            if (!processing) {
                processing = true;

                window.application.callApi('dating', 'match', 'dislike', {id: id}).then(
                    function () {
                        $('[data-content="matchgame-buttons"]').hide();
                        gotoNext();
                        processing = false;
                    },
                    function () {
                        processing = false;
                    }
                );
            }
        }

        //functions to handle the search preferences
        //---------------------------------------------------------------
        function openSearchPref() {
            $('[data-cnt="search"]').slideUp();
            $('[data-cnt="search-preferences"]').slideDown();
        }

        function closeSearchPref() {
            $('[data-cnt="search-preferences"]').slideUp();

            if (matchgameEnd) {
                $('[data-selector="matchgame-end"]').show();
            } else {
                $('[data-cnt="search"]').slideDown();
            }
        }

        function setSearchPref() {
            var searchPreferencesValues = ({
                matchGender : $('[data-field="matchGender"]').val(),
                matchRegion : $('[data-field="matchRegion"]').val(),
                minAge : $('[data-field="minAge"]').val(),
                maxAge : $('[data-field="maxAge"]').val()
            });

            if (!searchPreferencesValues.matchRegion) {
                $('[data-error="searchPrefError"]').show();
                return false;
            } else {
                //show the back to matchgame just in case someone was in the matchgame for the first time
                $('[data-error="searchPrefError"]').hide();
                $('[data-button="back-to-game"]').show();
            }


            updateSearchPreferences(searchPreferencesValues);
        }

        function updateSearchPreferences(postData) {
            var geoFilter = $('[data-field="matchRegion"] option:selected').data('filter') || {};

            //setsearch expects everything for some reason so we set unused stuff to 'all' so it has something

            //below syntax of displayregion must be done like this for ie11
            geoFilter[displayRegion] = postData.matchRegion;
            postData.gender = postData.matchGender;

            //setsearch expects everything for some reason so we set unused stuff to 'all' so it has something
            postData.geoIso      = (typeof geoFilter['iso'] !== 'undefined') ? geoFilter.iso : 'all';
            postData.geoLanguage = (typeof geoFilter['language'] !== 'undefined') ? geoFilter.language : 'all';
            postData.geoRegion1 = (typeof geoFilter['geoRegion1'] !== 'undefined') ? geoFilter.geoRegion1 : 'all';
            postData.geoRegion2 = (typeof geoFilter['geoRegion2'] !== 'undefined') ? geoFilter.geoRegion2 : 'all';
            postData.geoRegion3 = (typeof geoFilter['geoRegion3'] !== 'undefined') ? geoFilter.geoRegion3 : 'all';
            postData.geoRegion4 = (typeof geoFilter['geoRegion4'] !== 'undefined') ? geoFilter.geoRegion4 : 'all';
            postData.geoPostcode = (typeof geoFilter['postcode'] !== 'undefined') ? geoFilter.postcode : 'all';
            postData.geoLocality = (typeof geoFilter['geoLocality'] !== 'undefined') ? geoFilter.geoLocality : 'all';
            postData.geoTimezone = (typeof geoFilter['timezone'] !== 'undefined') ? geoFilter.timezone : 'all';


            window.application.callAjax('account', 'setSearch', postData).then(
                function () {
                    $('[data-cnt="search-preferences"]').slideUp();
                    updatedSearchPref = true;
                    gotoNext();
                });
        }

        function minMaxAgeCheck() {
            var currentMinAge = $('[data-field="minAge"]').val();
            var currentMaxAge = $('[data-field="maxAge"]').val();

            var $maxAge = $('[data-field="maxAge"]');
            var $minAge = $('[data-field="minAge"]');

            for (var i = 18; i < 100; i++) {
                if (i < currentMinAge) {
                    if (getIE() === false) {
                        $('option[value="' + i + '"]', $maxAge).prop('disabled', true);
                    } else {
                        $('option[value="' + i + '"]', $maxAge).hide();
                    }
                } else {
                    if (getIE() === false) {
                        $('option[value="' + i + '"]', $maxAge).prop('disabled', false);
                    } else {
                        $('option[value="' + i + '"]', $maxAge).show();
                    }
                }

                if (i > currentMaxAge) {
                    if (getIE() === false) {
                        $('option[value="' + i + '"]', $minAge).prop('disabled', true);
                    } else {
                        $('option[value="' + i + '"]', $minAge).hide();
                    }
                } else {
                    if (getIE() === false) {
                        $('option[value="' + i + '"]', $minAge).prop('disabled', false);
                    } else {
                        $('option[value="' + i + '"]', $minAge).show();
                    }
                }
            }
        }

        function getIE() {
            var agent = navigator.userAgent.toLowerCase();

            return (agent.indexOf('msie') != -1) ? parseInt(agent.split('msie')[1]) : false;
        }


        //click selectors to handle the search preferences
        //---------------------------------------------------------------

        $body.on('click', '[data-button="match-pref-save"]', function() {
            setSearchPref();
        });

        $('[data-field="minAge"], [data-field="maxAge"]').on('change', function() {
            minMaxAgeCheck();
        });

        $body.on('click', '[data-button="back-to-game"]', function() {
            closeSearchPref();
        });

        $body.on('click', '[data-link="matchgame-settings"]', function() {
            $('[data-selector="matchgame-end"]').hide();
            openSearchPref();
        });

        $body.on('click', '[data-close="matched"]', function() {
            gotoNext();
        });

        $('[data-cnt="search-result"]').delegate('[data-action]', 'click', function() {
            $('[data-content="matchgame-buttons"]').hide();

            matchgameModalGoesOut();

            if ($(this).hasClass('disabled')) {
                return;
            }

            $('[data-action]', '.buttons').addClass('disabled');
            $('[data-action]', '.buttons').off('click');

            if ($(this).data('action') === 'like') {
                likeProfile($(this).attr('data-id'));
                return;
            } else {
                dislikeProfile($(this).attr('data-id'));
            }
        });

        // Public API
        return {
            initialSearch: initialSearch
        };
    }

    return {

        // Get the Singleton instance if one exists
        // or create one if it doesn't
        getInstance: function () {

            if (!instance) {
                instance = init();
            }

            return instance;
        }
    };
})();
