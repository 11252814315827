(function() {
    var Favorite;

    Favorite = (function() {
        /**
         * Constructs a new Favorite
         */
        function Favorite(application, options)
        {
            this.defaults = {};

            if (typeof options === 'undefined') {
                options = {};
            }

            // Set this options to defaults
            this.options = this.defaults;

            // Merge given options over all options
            this.options = $.extend(true, {}, this.options, options);

            this.application             = application;
        }

        Favorite.prototype.add = function(clientId)
        {
            var deferred = new $.Deferred();

            $.when(
                this.application.callAjax('favorites', 'add', {clientid : clientId})
            ).then(
                function() {
                    deferred.resolve();
                }
            );

            return deferred.promise();
        }

        Favorite.prototype.remove = function(clientId)
        {
            var deferred = new $.Deferred();

            $.when(this.application.callAjax('favorites', 'remove', {clientid : clientId})).then(
                function() {
                    deferred.resolve();
                }
            );

            return deferred.promise();
        }

        return Favorite;

    })();

    this.Favorite = Favorite;
}).call(this);
