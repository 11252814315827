/**
 *
 * @type {{getInstance}}
 */
var profileActionsService = (function () {

    "use strict";

    // Instance stores a reference to the Singleton
    var instance;

    function init() {

        // Private variables and functions
        var flirtVariables = {};

        function bindEventListeners() {
            $('[data-dropdown="send-flirt-dropdown"]').on('click', openFlirtsDropdown);
        }

        function openFlirtsDropdown() {
            $('[data-content="selected-flirt-wrapper"]').hide();
            $('[data-dropdown="flirts-list"]').slideToggle(500);
        }

        bindEventListeners();

        /**
         * Function to show the selected flirt text in $('[data-content="selected-flirt"]')
         * @param {{String}} selectedFlirt - The current selected flirt text
         */
        function displaySelectedFlirt(selectedFlirt) {
            $(selectedFlirt).next("input[name='flirt']").attr("checked", true);
            $('[data-content="selected-flirt-wrapper"]').show();
            $('[data-content="selected-flirt"]').html($(selectedFlirt).html());
            $('[data-dropdown="flirts-list"]').slideToggle(500);
            $('[data-send="blink"]').data('blink', $(selectedFlirt).find('[data-field="flirt-type-text"]').text());
        }

        /**
         * Function to send the flirt
         * @param {String} flirt - The text of the flirt
         * @param {string} to - The receiver of the flirt
         */
        function sendFlirt(flirt, to) {
            $.when(
                window.application.callAjax(
                    'message',
                    'flirt',
                    {
                        to    : to,
                        flirt : flirt
                    }
                )
            ).then(
                function(response) {
                    var $creditsCounter = $('[data-counter="credits.left"]:visible').first(),
                        creditsLeft     = parseInt($creditsCounter.text());

                    if (creditsLeft > 0) {
                        $('[data-counter="credits.left"]').text(creditsLeft - 1);
                    }

                    $('[data-content="selected-flirt-wrapper"]').slideToggle();
                    $('[data-overlay]').show();
                    $("[data-flirt='success']").show();

                    setTimeout(function() {
                        $("[data-flirt='success']").hide();
                    }, 5000);

                    $("html, body").animate({
                        scrollTop: 0
                    }, 600);
                },
                function(error) {
                    var errorMsg = winkErrors[error[0].code];

                    if (error[0].code === 'insufficient_credits') {
                        msg.options.onUnsufficientCredits(
                            msg,
                            null,
                            {
                                to         : flirtVariables.profileClientId,
                                subject    : '',
                                message    : flirt,
                                attachment : null,
                                msgtype	   : 'flirt'
                            }
                        );
                        return;
                    }

                    $("[data-flirt='error']").text(errorMsg.replace('{{clientname}}', flirtVariables.profileClientName));

                    $('[data-content="selected-flirt-wrapper"]').slideToggle();
                    $('[data-overlay]').show();
                    $("[data-flirt='error']").show();

                    setTimeout(function() {
                        $("[data-flirt='error']").hide();
                    }, 5000);

                    $("html, body").animate({
                        scrollTop: 0
                    }, 600);
                }
            );
        }


        // Public API
        return {
            sendFlirt: sendFlirt,
            displaySelectedFlirt: displaySelectedFlirt,
            openFlirtsDropdown: openFlirtsDropdown,
            flirtVariables: flirtVariables
        };

    }

    return {

        // Get the Singleton instance if one exists
        // or create one if it doesn't
        getInstance: function () {

            if (!instance) {
                instance = init();
            }

            return instance;
        }

    };

})();
