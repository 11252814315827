/**
 * mailboxService.js
 * Handles the functionality for displaying the messages on the various mailbox pages
 */

/**
 *
 * @type {{getInstance}}
 */

window.mailboxService = (function (Hogan, moment) {

    // Instance stores a reference to the Singleton
    var instance;

    function init() {

        'use strict';

        // Private variables and functions
        // Grab the html from the inbox div
        var inboxHtml = $('#inbox-item').html();
        var $body = $('body');

        //no subject is for internal use only
        var defaultSubject = 'No subject';

        // Compile the inbox HTML to Hogan
        var $hoganTemplate = Hogan.compile(inboxHtml);

        var clicked = false;
        var $deleteBulkButton = $('[data-button="delete-bulk-button"]');

        var $inboxButton = $('[data-button="inbox"]');
        var $outboxButton = $('[data-button="outbox"]');
        var $messagesContainer = $('[data-container="messages"]');
        var $noMessagesElement = $('[data-msg="no-messages"]');
        var $paginationContainer = $('[data-selector="pagination-container"]');
        var $mailboxActions = $('[data-mailbox-actions]');

        var confirmDeleteText = window.application.options.inbox.confirmDeleteText;

        var imgService = imageService.getInstance();

        //set the default postData
        var postData = {};
        if(!window.emoji) window.emoji = new EmojiConvertor();

        //functions for mailbox method 1 (overview page method)
        //---------------------------------------------------------------------------------------------

        var setInActiveMessageOverview = function (mailboxPostData) {
            postData = mailboxPostData.inActiveMessages;
            initialMailboxLoad(mailboxPostData.inActiveMessages.conversation);
        };

        var setActiveMessageOverview = function (mailboxPostData) {
            postData = mailboxPostData.activeMessages;
            overviewMailboxLoad(mailboxPostData.activeMessages.conversation);
        };

        var setPostData = function (mailboxPostData) {

            //if you are on the overview page of the mailbox do a different call
            if (mailboxPostData.overviewPage) {
                setActiveMessageOverview(mailboxPostData);
                setInActiveMessageOverview(mailboxPostData);
            } else {
                postData = mailboxPostData;
                initialMailboxLoad();
            }
        };

        var initialMailboxLoad = function (isRerender) {
            if (isRerender !== 'rerender' && isAllMsgLoaded) {
                return false;
            }

            var $endMailbox = $('[data-selector="end-mailbox"]').last();
            var $loadedContent = $('.loadedcontent');
            var loaderHTML = '<div class="spinner-wrapper" data-selector="spinner-wrapper"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></div>';

            function removeSpinner() {
                $('[data-selector="spinner-wrapper"]').remove();
            }

            $loadedContent.last().append(loaderHTML);
            $endMailbox.html('');

            //initial load inbox
            window.application.callAjax('messagebox', 'loadbox', postData, true)
                .then(function (data) {
                    var isLastMsgBundle = parseInt(postData.limit, 10) > data.messages.length;

                    //convert the date send
                    convertMessageSentTime(data);

                    //do the subject fallback
                    messageSubjectFallback(data);

                    postData.offset = postData.offset + 10;

                    var $appendedItems = $('<div />', {'class': 'loadedcontent'});

                    data.messages.forEach(function (message) {
                        var $wrapper = $('<div />');

                        //unblur the default photo if platform has blurring in it
                        message.profile.defaultPhoto.original = imgService.unblurImage(message.profile.defaultPhoto.original);

                        //create jquery context variable of each message
                        var $renderTmpl = $wrapper.html( $($hoganTemplate.render(message)) );

                        //Put the message in the emoji function and replace the current html with the new html
                        $renderTmpl.find('[data-emojime]').replaceWith( $renderTmpl.emoji().html() );

                        //append everything to the correct class
                        $appendedItems.append($renderTmpl.html());
                    });

                    removeSpinner();

                    isAllMsgLoaded = isLastMsgBundle;

                    var endMsg = isLastMsgBundle
                        ? window.application.options.allConversationsLoaded
                        : window.application.options.scrollDownForMsg;

                    $endMailbox.html('<div style="text-align: center">' + endMsg + '</div>');

                    //If firstTime is true then replace all the content of container and set it to false
                    if (postData.firstTime) {
                        $('[data-container="messages"]').html($appendedItems);
                        postData.firstTime = false;
                    }
                    //append the additional scrollable content
                    else {
                        $('[data-container="messages"]').append($appendedItems);
                    }

                    //if infinite scroll ever returns use this
                    // processing = false;
                });
        };

        var overviewMailboxLoad  = function(messagesState) {
            window.application.callAjax('messagebox', 'loadbox', postData, true).then(
                function (data) {

                    //convert the date send
                    convertMessageSentTime(data);

                    //do the subject fallback
                    messageSubjectFallback(data);

                    var $appendedItems = $('<div />', {'class': 'loadedcontent'});

                    // if active messages is empty hide the div
                    if (messagesState == 'active' && data.messages.length == 0) {
                        $('[data-wrapper="inactive"]').removeClass('overview-inactive-box-placeholder');
                        $('[data-cnt="active"]').hide();
                        return;
                    }

                    data.messages.forEach(function (message) {
                        var $wrapper = $('<div />');

                        //unblur the default photo if platform has blurring in it
                        message.profile.defaultPhoto.original = imgService.unblurImage(message.profile.defaultPhoto.original);

                        //create jquery context variable of each message
                        var $renderTmpl = $wrapper.html( $($hoganTemplate.render(message)) );

                        //Put the message in the emoji function and replace the current html with the new html
                        $renderTmpl.find('[data-emojime]').replaceWith( $renderTmpl.emoji().html() );

                        //append everything to the correct class
                        $appendedItems.append($renderTmpl.html());
                    });

                    $('.' + messagesState + '-overview').append($appendedItems);
                }
            );
        };

        var snowflake = function () {
            $noMessagesElement.show();
            loadMessages = function() {};
        };

        //function for method 2 mailbox (in/outbox)
        //---------------------------------------------------------------
        var loadMessages = function(mailboxPostData) {
            window.application.callAjax('messagebox', 'loadBox', mailboxPostData, true).then(function(data) {

                //empty everything
                $paginationContainer.html('');
                $messagesContainer.html('');

                var pagination = data.pagination;

                //check if there are messages in the call. Else show a string that there are no messages
                if (data.messages.length === 0) {
                    $mailboxActions.hide();
                    $noMessagesElement.show();
                    return false;
                } else {
                    $mailboxActions.show();
                    $noMessagesElement.hide();
                }

                //if your current page is higher than the total amount of pages remove the hash from the url and go to page 1
                if (pagination.currentPage > pagination.totalPages && pagination.totalPages !== 0) {
                    history.pushState('', document.title, window.location.pathname);
                    mailboxPostData.offset = 0;
                    loadMessages(mailboxPostData);
                    return false;
                }

                //if the mailboxtype is 2 (outbox) set all the messages on read with this function
                if (mailboxPostData.type == '2') {
                    convertMessageStatus(data);
                }

                //convert the date send
                convertMessageSentTime(data);

                //do the subject fallback
                messageSubjectFallback(data);

                //render the messages in the template
                renderMessages(data);

                //parse the pagination
                parsePagination(pagination, mailboxPostData);

            });
        };

        //functions to handle deletions
        //---------------------------------------------------------------------------------------------

        //function to delete a single message > needs confirmDeleteId
        var deleteSingleMessage = function (confirmDeleteId, deletedUnread) {
            var postDataProfiles = {profile_ids: [confirmDeleteId]};

            window.application.callAjax('messagebox', 'delete', postDataProfiles, true)
                .then(function () {
                    // reset mailboxvalues
                    resetMailboxValues();

                    // rerender mailbox
                    initialMailboxLoad('rerender');

                    // update the unread mail counter
                    updateMailCounter(deletedUnread);
                });
        };

        var deleteMethodInBulk = function () {
            //confirm delete text; are you sure
            var confirmDeletion = false;
            confirmDeletion = confirm(confirmDeleteText);

            //empty array holder for selected deletion
            var selectedForDeletion = [];

            if(confirmDeletion) {

                $('input:checkbox[name="deletion"]:checked').each(function () {
                    selectedForDeletion.push($(this).attr('data-delete'));
                });

                var postDataProfiles = {profile_ids: selectedForDeletion};

                window.application.callAjax('messagebox', 'delete', postDataProfiles, true).then(
                    function () {

                        // //reset mailboxvalues and rerender mailbox, overview style mailbox delete
                        // resetMailboxValues();
                        // initialMailboxLoad();

                        //pagination style reload loadbox
                        var limit = 10;
                        var mailboxPostData = {
                            limit: limit,
                            offset:(window.location.hash) ? (parseInt(window.location.hash.substring(1), 10) - 1) * limit : 0,
                            type: 1
                        };
                        loadMessages(mailboxPostData);
                    }
                );
            }
        };

        /**
         * Function to check if any checkboxes are selected and if they are put them in an array.
         */
        function deleteCheckboxChecker() {
            //empty array holder for selected deletion
            var selectedForDeletion = [];

            $('input:checkbox[name="deletion"]:checked').each(function () {
                selectedForDeletion.push($(this).attr('data-delete'));
            });

            if (selectedForDeletion.length !== 0) {
                $deleteBulkButton.show();
            }
            else {
                $deleteBulkButton.hide();
            }
        }


        //functions to build the mailbox page with the items
        //---------------------------------------------------------------------------------------------
        var convertMessageStatus = function(data) {
            data.messages.forEach(function (message) {
                message.read = true;
            });
        };

        var convertMessageSentTime = function (data) {
            var now = moment(); // eslint-disable-line no-unused-vars

            data.messages.forEach(function(currentValue) {
                moment.locale(window.application.options.customSettings.momentLanguage);

                //client browser timezone
                var clientBrowserTimezone = moment.tz.guess();

                //put the timestamp of the message in a correct moment format
                var messageTimezone = moment(currentValue.sent);

                //current client time and date
                var now = moment().endOf('day');

                //based on the difference format the date differently
                var clientTimezone =  messageTimezone.tz(clientBrowserTimezone).format('YYYY-MM-DD HH:mm:ss');

                // currentValue.sent = clientTimezone;
                currentValue.sent = window.application.service.formatDate(clientTimezone, now);
            });
        };

        //if subject is empty set a default subject
        var messageSubjectFallback = function (data) {

            data.messages.forEach(function(currentValue) {
                if (currentValue.subject === null || currentValue.subject === 0 || !currentValue.subject) {
                    currentValue.subject = defaultSubject;
                }
            });
        };

        //setup the pagination
        var parsePagination = function(pagination, mailboxPostData) {
            //pagination
            $('[data-selector="pagination-container"]').pagination(pagination.totalItems, {
                items_per_page: pagination.limit,
                prev_text : window.application.options.prevText,
                next_text : window.application.options.nextText,
                current_page : pagination.currentPage,
                prev_show_always : false,
                next_show_always : false,
                callback : function(newPage) {
                    var currentPage = ((mailboxPostData.offset/pagination.limit)+1);
                    mailboxPostData.offset   = ((newPage-1)*pagination.limit);


                    if (newPage !== currentPage) {
                        loadMessages(mailboxPostData);
                        window.scrollTo(0, 0);
                    }

                    return false;
                }
            });
        };

        /**
         * Render the received messagess in the template
         * @param data - an array of the messages that you received
         */
        var renderMessages = function(data) {
            var appendedItems = $messagesContainer;

            data.messages.forEach(function (message) {
                var $wrapper = $('<div />');

                var splash = message.profile.media ? message.profile.media.find(function (photo) {
                    return photo.isSplash;
                }) : undefined;

                if (splash) {
                    message.profile.defaultPhoto.original = splash.resolutions.xsmall.unblurred;
                }

                //create jquery context variable of each message
                var $renderTmpl = $wrapper.html( $($hoganTemplate.render(message)) );

                //Put the message in the emoji function and replace the current html with the new html
                $renderTmpl.find('[data-emojime]').replaceWith( $renderTmpl.emoji().html() );

                //append everything to the correct class
                appendedItems.append($renderTmpl.html());
            });
            //append the generated templates in the correct data-container
            $messagesContainer.append(appendedItems);

        };

        //general functions
        //---------------------------------------------------------------------------------------------

        var updateMailCounter = function (deletedUnread) {
            if (typeof deletedUnread === 'undefined' || deletedUnread === 0) {
                return false;
            }

            var $counter = $('[data-selector="new-mail-counter"]');
            var current = parseInt($counter.eq(1).text(), 10);

            $counter.text(current - deletedUnread);
        };

        var resetMailboxValues = function () {
            //reload the mailbox
            $('[data-container="messages"]').empty();
            postData.firstTime = true;
            postData.offset = 0;
        };

        var isAllMsgLoaded = false;

        var clickEventMailbox = function (clickedMessage) {

            //get the profile id from clicked element
            var profileId = clickedMessage.attr('data-profile-id');

            //set the redirect url
            var redirectUrl = '/conversation/' + profileId;

            //redirect to the redirect url
            window.location.href =  redirectUrl;

        };

        function answerSexProposal(answer, profile) {
            postData = {
                'to': profile,
                'answer' : answer
            };

            window.application.callAjax('message', 'answerProposal', postData, true, window.actionAnswerProposalUrl).then(
                function() {
                    location.reload();
                },
                function() {
                }
            );
        }


        $body.on('click', '.proposal-btn', function (e) {
            e.stopPropagation();
            var answer = ($(this).attr('value'));
            var profile = ($(this).attr('data-profile-name'));
            answerSexProposal(answer, profile);
        });

        //Redirect click handlers
        //---------------------------------------------------------------------------------------------

        //redirect to clicked conversation
        $body.on('click', '[data-inbox="redir-conversation"]', function() {
            clickEventMailbox($(this));
        });

        //go to the inbox
        $body.on('click', '[data-button="inbox"]', function() {
            var limit = 10;
            var mailboxPostData = {
                type: '1',
                offset: 0,
                limit: limit
            };

            loadMessages(mailboxPostData);
            $outboxButton.removeClass('button-mailbox-selected');
            $inboxButton.addClass('button-mailbox-selected');
        });

        //go to the outbox
        $body.on('click', '[data-button="outbox"]', function() {
            var limit = 10;
            var mailboxPostData = {
                type: '2',
                offset: 0,
                limit: limit
            };

            loadMessages(mailboxPostData);
            $inboxButton.removeClass('button-mailbox-selected');
            $outboxButton.addClass('button-mailbox-selected');
        });

        //Deletion click handlers
        //---------------------------------------------------------------------------------------------

        //handle when you click the confirm delete button
        $body.on('click', '[data-column="confirm-delete"]', function (e) {

            //stop propogation so you dont get send to conversation page
            e.stopPropagation();

            //Get the message ID from the clicked button
            var confirmDeleteId = $(this).attr('data-message');

            var deletedUnread = $(this).parents('.message-unread').length;

            deleteSingleMessage(confirmDeleteId, deletedUnread);
        });

        //if you check a checkbox (bulk deletion) then do that function
        $body.on('change click', '[data-input="delete"]', function() {
            deleteCheckboxChecker();
        });

        //click event has to be seperate in order to not change page
        $body.on('click', '[data-input="delete"]', function(e) {
            e.stopPropagation();
        });

        //select all checkboxes for bulk deletion
        $('[data-button="select-messages"]').on('click', function() {
            $('[data-input="delete"]').prop('checked', !clicked);
            clicked = !clicked;

            if(clicked) {
                $deleteBulkButton.show();
                $('[data-button="select-all"]').hide();
                $('[data-button="deselect-all"]').show();
            }
            else {
                $deleteBulkButton.hide();
                $('[data-button="deselect-all"]').hide();
                $('[data-button="select-all"]').show();
            }
        });

        //click event for delete button (bulk method)
        $deleteBulkButton.click(function () {
            deleteMethodInBulk();
        });

        // Public API
        return {
            initialMailboxLoad: initialMailboxLoad,
            setPostData : setPostData,
            loadMessages: loadMessages,
            snowflake : snowflake
        };
    }

    return {

        // Get the Singleton instance if one exists
        // or create one if it doesn't
        getInstance: function () {

            if (!instance) {
                instance = init();
            }

            return instance;
        }

    };
})(window.Hogan, window.moment);


