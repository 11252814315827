(function() {
    var dataNamespace = 'app.conversation',
        old   = $.fn.conversation,
        transit = false,
        loadNext = false,
        mobileBindsDone = false,
        desktopBindsDone = false,
        firstMessageRendered = false,
        showMessageSentAlert = false,
        Conversation = function (container, options) {
            var self     = this,
                override = window.storage.get('conversation', 'override-msgid-' + window.location.href);

            this.cnt     = $(container);
            this.options = options;

            this.options.isMobile = $(this.options.container.mobile).is(':visible');

            this.initialDataProcessed = false;

            if (override !== null) {
                this.options.msgid = override;
            }

            this.runtime = {
                postData : {
                    offset     : 0,
                    limit      : 10,
                    profile_id : this.options.profile_id
                },
                template : null,
                container : null
            };

            init.call(this);
        };
    if(!window.emoji) window.emoji = new EmojiConvertor();

    Conversation.defaults = {
        page      : 1,
        'with'    : null,
        msgid     : null,
        isMobile  : false,
        direction : 'append',
        endConversation : '[data-conversation="end-conversation"]',
        mobileUploadField : '#upload-mobile-attachment',
        container : {
            mobile         : '[data-conversation="mobile"]',
            desktop        : '[data-conversation="desktop"]',
            history        : '[data-conversation="container"]',
            desktopHistory : '.message-inner-container.desktop',
            firstMessage   : '[data-conversation="first-message"]',
            pagination     : '[data-conversation="pagination"]'
        },
        templates : {
            mobile  : '#template-history-mobile',
            desktop : '#template-history-desktop'
        },
        form : {
            mobile : {
                attachment      : 'input[name="attachment"]',
                message         : 'textarea[name="message"]',
                subject         : 'input[name="subject"]',
                conversationOn  : 'input[name="conversation-on"]',
                conversationTo  : 'input[name="conversation-to"]',
                send            : '[data-conversation="send"]',
                noCredits       : '[data-conversation="no-credits"]'
            },
            desktop : {
                conversationOn  : 'input[name="msgid"]',
                conversationTo  : 'input[name="to"]',
                subject         : 'input[name="subject"]',
                message         : 'textarea[name="message"]',
                container       : '[data-conversation="desktop-send"]',
                attachment      : '#desktop-attachment',
                send            : '[data-conversation="send"]'
            }
        }
    };


    function getDraft() {
        return window.storage.get('message', 'send' + window.location.href);
    }

    function delDraft() {
        return window.storage.del('message', 'send' + window.location.href);
    }

    function setDraft (postData) {
        return window.storage.set('message', 'send' + window.location.href, postData, true);
    }

    sendDraft = function(message) {
        var postData  = getDraft(),
            selectors = {};

        if (postData === null) {
            return;
        }

        delDraft();

        selectors = this.options.form.desktop;

        $(selectors.conversationOn, this.cnt).val(postData.msgid);
        $(selectors.conversationTo, this.cnt).val(postData.to);
        $(selectors.subject, this.cnt).val(postData.subject);
        $(selectors.message, this.cnt).val(postData.message);

        if (this.options.isMobile === true) {
            $(selectors.attachment, this.cnt).val(postData.attachment);
        }

        $(this.options.form.desktop.send, this.cnt).addClass('disabled');

        message.send(
            postData.msgid,
            postData.to,
            postData.subject,
            postData.message,
            postData.attachment
        );
    };

    function handleUnsufficientCredits(message, data, postData) {
        var self    = this,
            $cnt    = this.runtime.container;

        setDraft(postData);

        $(self.options.form.desktop.send, self.cnt).addClass('disabled');

        $('textarea[name="message"]').on('change', function() {
            var data  = getDraft(),
                $this = $(this);

            data[$this.attr('name')] = $this.val();
            setDraft(data);
        });

        $('.payment-container').show();
        $(".ppt-container",'.payment-container').hide();
        $("[data-action='buyCredits']").addClass('is-active');

        $("[data-action='quick']",'.payment-container').show();
        $('[data-payment="quick-credits"]').click(function(){
            $('[data-column="hidden-credit-column"]').hide();
            $('[data-selector="quick-credits-step2"]').hide();
        });

    }

    function handleOnSuccess(data) {
        var self    = this,
            timeout = 250;

        firstMessageRendered             = true;
        this.runtime.postData.offset     = 0;
        this.runtime.postData.message_id = data.message_id;

        window.storage.set(
            'conversation',
            'override-msgid-' + window.location.href,
            data.message_id,
            true
        );

        if (self.options.development == 1) {
            timeout = 2000;
        }

        if (typeof this.options.blurredProfilePicture !== "undefined") {
            var blurredProfilePicture = this.options.blurredProfilePicture;
            var imgService = imageService.getInstance();
            $('[data-img="profile"]').attr("src", imgService.unblurImage(blurredProfilePicture));
        }

        setTimeout(function() {
            init.call(self);
        }, timeout);
    }

    function desktopBinds() {
        var self     = this,
            $cnt     = this.runtime.container,
            selects  = this.options.form.desktop,
            message  = null,
            deferred = new $.Deferred();

        yepnope({
            test : $.createUploadForm,
            nope : '/js/jquery.ajaxfileupload.js',
            complete : function() {
                message = new window.Message(
                    window.application,
                    {
                        messageFormSelector       : selects.container,
                        submitButtonSelector      : selects.send,
                        errorMessageSelector      : '.error-message',
                        attachmentElementId       : selects.attachment,
                        onUnsufficientCredits : function(message, data, postData) {
                            handleUnsufficientCredits.call(self, message, data, postData);
                        },
                        onSuccess : function(data) {
                            var $button = $('[data-conversation="send"]');
                            $button.addClass('disabled');

                            setTimeout(function() {
                                $("[data-message='success']").hide();
                                $button.removeClass('disabled');
                            }, 2000);

                            handleOnSuccess.call(self, data);
                        },
                        onError : function(obj, code, msg) {

                            var error;
                            $.each( self.options.upload_errors, function( key, value ) {
                                if (key === code) {
                                    error = value;
                                }
                            });

                            var $button = $('[data-conversation="send"]')
                            $button.addClass('disabled');
                            window.scrollTo(0, 0);
                            $("[data-message='error']").text(error).show();

                            setTimeout(function() {
                                $("[data-message='error']").hide();
                                $button.removeClass('disabled');
                            }, 2000);
                        }
                    }
                );

                deferred.resolve(message);
            }
        });

        return deferred.promise();
    }

    function init() {
        var self = this,
            sendMessage = function(sender) {
                sendDraft.call(self, sender);
            };

        this.options.direction = 'append';

        yepnope({
            test : (typeof Hogan !== 'undefined'),
            nope : '/js/hogan-2.0.0.min.js',
            complete : function() {
                var $template;


                $template = $(self.options.templates.desktop);
                if ($template.length === 0) {
                    throw new TypeError('Missing desktop template');
                }

                self.runtime.container = $(self.options.container.desktop, self.cnt);

                if (desktopBindsDone === false) {
                    desktopBinds.call(self).then(sendMessage);
                    desktopBindsDone = true;
                }


                self.runtime.template = Hogan.compile($template.html());

                if (
                    self.options.initialData.result.messages.length === 0 &&
                    $('.message-item', self.cnt).length === 0
                ) {
                    $('[data-notfirst]', self.cnt).hide();
                    $('[data-notfirst]', self.cnt).hide();
                    $('[data-first="subject"]', self.cnt).attr('placeholder', 'Onderwerp');
                    self.loadConversation.call(self, true);
                } else {
                    $('input[name="subject"]', self.cnt).attr('type', 'hidden');
                    self.loadConversation.call(self, true);
                }
            }
        });
    }

    function ajax(postData) {
        var deferred = new $.Deferred();

        $.ajax({
            url      : '/json/messagebox/list',
            data     : postData,
            dataType : 'json',
            type     : 'POST',
            success  : function(data) {
                if (data.error) {
                    return deferred.reject(data.error.code, data.error.message, data.error.data);
                }

                return deferred.resolve(data.result);
            },
            error : function(jqXHR, title, message) {
                var data = JSON.parse(jqXHR.responseText);
                return deferred.reject(data.error.code, data.error.message, data.error.data);
            }
        });

        return deferred.promise();
    }

    function insertMessage(emptyContainer, messages, profiles, pagination) {
        var self     = this,
            $cnt     = $(this.options.container.history, this.runtime.container),
            template = null,
            i        = null,
            bindOn   = null,
            middle   = Math.floor(messages.length / 2),
            j        = 1,
            deferred = new $.Deferred();

        if (emptyContainer) {
            $cnt.html('');
        } else if (this.options.direction === 'prepend') {
            $('.start-conversation', $cnt).remove();
        }

        var mobileBreakpoint = window.application.service.breakpoints.getMobile();

        if ($( window ).width() < mobileBreakpoint) {
            messages.reverse();
        }

        self.setWinkClass(messages);

        var now = moment();

        messages.forEach(function(message) {
            moment.locale(window.application.options.customSettings.momentLanguage);

            //client browser timezone
            var clientBrowserTimezone = moment.tz.guess();

            //put the timestamp of the message in a correct moment format
            var messageTimezone = moment(message.displayDate);

            //current client time and date
            var now = moment().endOf('day');

            //based on the difference format the date differently
            var clientTimezone =  messageTimezone.tz(clientBrowserTimezone).format('YYYY-MM-DD HH:mm:ss');

            message.displayDate = window.application.service.formatDate(clientTimezone, now);
        });

        if (messages.length) {
            for (i = 0; i < messages.length; i++) {
                if (messages[i].subject === null || messages[i].subject.length === 0) {
                    messages[i].subject = this.options.defaultSubject;
                }

                template = $(this.runtime.template.render({
                    message : messages[i],
                    profile : profiles[messages[i].authorId]
                }));

                template.find('[data-emojime]').emoji();

                if (this.options.direction === 'prepend') {
                    $cnt.prepend(template);
                    if (j % middle === 0) {
                        bindOn = template;
                    }
                } else {
                    $cnt.append(template);
                }

                $('.message-container', template).css({ minHeight :
                $('.profile-photo', template).width() + 'px'
                });

                j++;
            }
        } else {
            if (pagination.currentPage === 1) {
                template = $(this.runtime.template.render({
                    message : {
                        body: window.application.options.conversationEmptyMsg
                    },
                    profile : 'profiles[messages[i].authorId]'
                }));

                $cnt.append(template);
            }
        }

        if (this.options.direction === 'prepend') {
            if (emptyContainer) {
                setTimeout(function() {
                    var $cnt = $('[data-conversation="container"]', self.runtime.container);
                    $cnt.scrollTop($cnt.prop('scrollHeight'));
                }, 1000);
            }

            if (pagination.loadNext === false) {
                $cnt.prepend('<div class="start start-conversation">-- ' + this.options.startConversation + ' --</div>');
                $(this.options.endConversation, this.cnt).show();
            } else if (pagination.loadNext === true &&pagination.totalItems === 0) {
                $cnt.prepend('<div class="start start-conversation">-- ' + this.options.startConversation + ' --</div>');
                $cnt.prepend('<img class="profile-img only-mobile" src="' + $('img', '.profile-data').attr('src') + '" data-first />');
                $(this.options.endConversation, this.cnt).show();
            } else {
                $(this.options.endConversation, this.cnt).hide();

                yepnope({
                    test : $.fn.appear,
                    nope : '/js/jquery.appear.js',
                    complete : function () {
                        bindOn.on('appear', function() {
                            $(this).unbind('appear');

                            self.loadNext.call(
                                self,
                                self.runtime.postData.offset + self.runtime.postData.limit
                            );

                        }).appear();
                    }
                });
            }


        }
        deferred.resolve();
        return deferred.promise();
    }

    function parsePagination(pagination) {

        var $cnt  = $(this.options.container.desktopHistory, this.cnt),
            $elem = $('[data-conversation="pagination"]'),
            self  = this;

        $cnt.append($elem);

        $elem.pagination(
            pagination.totalItems,
            {
                load_first_page     : false,
                link_to             : '#__id__',
                prev_text           : self.options.paginationPrevText,
                next_text           : self.options.paginationNextText,
                items_per_page      : pagination.limit,
                num_display_entries : 10,
                current_page        : pagination.currentPage,
                prev_show_always    : false,
                next_show_always    : false,
                callback            : function(page){
                    var offset = (page * self.runtime.postData.limit) - self.runtime.postData.limit;
                    self.loadNext.call(self, offset, true);
                }
            }
        );
    }

    Conversation.prototype.setWinkClass = function(messages) {
        var self = this;

        messages.forEach(function (message) {
            self.options.winks.forEach(function (wink) {
                if (wink.message == message.subject) {
                    message.class = wink.class;
                }
            })
        });
    }

    Conversation.prototype.loadConversation = function (clean) {
        var self = this;

        if (
            typeof self.options.initialData !== 'undefined' &&
            null !== self.options.initialData &&
            false === self.initialDataProcessed
        ) {
            insertMessage.call(
                self,
                clean,
                self.options.initialData.result.messages,
                self.options.initialData.result.profiles,
                self.options.initialData.result.pagination
            ).then(function () {
                parsePagination.call(self, self.options.initialData.result.pagination);
            });

            self.initialDataProcessed = true;

            if (self.options.offset == 0) {
                return;
            } else {
                this.runtime.postData.offset = self.options.offset;
            }
        }


        if (self.options.snowflake !== 'true') {
            ajax(this.runtime.postData).then(
                function (data) {
                    insertMessage.call(
                        self,
                        clean,
                        data.messages,
                        data.profiles,
                        data.pagination
                    ).then(function () {
                        parsePagination.call(self, data.pagination);
                    });
                },
                function (code, message, data) {
                    window.application.alert(self.options.errorTitle, message);
                }
            );
        }
    };

    Conversation.prototype.loadNext = function (offset, clean) {
        var self = this;

        clean = clean || false;

        if (transit === true) {
            loadNext = true;
            return;
        }

        transit = true;

        this.runtime.postData.offset = offset;

        ajax(this.runtime.postData).then(
            function (data) {
                insertMessage.call(
                    self,
                    clean,
                    data.messages,
                    data.profiles,
                    data.pagination
                ).then(function () {
                    parsePagination.call(self, data.pagination);
                });

                transit = false;
                if (loadNext === true) {
                    loadNext = false;

                    self.loadNext.call(
                        self,
                        self.runtime.postData.offset + self.runtime.postData.limit
                    );
                }
            },
            function (code, message, data) {
                window.application.alert(self.options.errorTitle, message);
            }
        );
    };

    // Register plugin
    $.fn.conversation = function (option) {
        var callArgs = arguments;

        result = this.each(function() {
            var $this   = $(this),
                data    = null,
                options = null,
                args    = [];

            data    = $this.data(dataNamespace);
            options = $.extend(true, {}, Conversation.defaults, $this.data(), option);

            if (data === undefined) {
                $this.data(dataNamespace, (data = new Conversation(this, options)));
            }

            if (typeof option === 'string') {
                if (data[option] === undefined) {
                    throw 'Method ' + option + ' does not exists';
                }

                Array.prototype.push.apply(args, callArgs);
                args.shift();

                // call methods
                return data[option].apply(data, args);
            }
        });
        return result;
    };

    $.fn.conversation.Constructor = Conversation;

    // No conflict
    $.fn.conversation.noConflict = function () {
        $.fn.conversation = old;
        return this;
    };
}());
