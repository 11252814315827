(function($) {

    function WebStorageAdapter()
    {
        if (typeof localStorage === 'undefined') {
            throw new TypeError('Missing localStorage object');
        }

        if (typeof sessionStorage === 'undefined') {
            //throw new TypeError('Missing sessionStorage object');
        }
    }

    function createKey(namespace, key) {
        return namespace + '::' + key;
    };

    function localKeyExists(fullKey) {
        if (typeof localStorage === 'undefined') {
            return false;
        }
        return (localStorage[fullKey] !== undefined);
    };

    function sessionKeyExists(fullKey) {
        if (typeof sessionStorage === 'undefined') {
            return false;
        }
        return (sessionStorage[fullKey] !== undefined);
    };

    WebStorageAdapter.prototype.get = function(namespace, key)
    {
        var fullKey     = createKey(namespace, key),
            result      = null,
            data        = null,
            now         = new Date().getTime(),
            ignoreParse = false;

        if (sessionKeyExists(fullKey)) {
            result = sessionStorage[fullKey];
        } else if (localKeyExists(fullKey)){
            result = localStorage[fullKey];
        } else {
            data   = $.cookies.get(fullKey);
            ignoreParse = true;
        }

        if (false === ignoreParse) {
            if (result === null || result === 'null') {
                return null;
            }
            try {
                data = JSON.parse(result);
            } catch (e) {
                this.del(namespace, key);
                return null;
            }
        }

        if (null === data) {
            return null;
        }

        if (data.ttl === 0) {
            return data.data;
        }

        if ((data.created + data.ttl) < now) {
            this.del(namespace, key);

            return null;
        }

        return data.data;
    };

    WebStorageAdapter.prototype.set = function(namespace, key, value, session, ttl)
    {
        var expiresAt   = new Date(),
            domainParts = window.location.host.split('.'),
            fullKey     = createKey(namespace, key),
            data        = {
                created : new Date().getTime(),
                data    : value,
                ttl     : ttl !== undefined ? (ttl * 1000) : 86400000
            },
            cookieOptions;

        expiresAt.setTime(
            expiresAt.getTime() + (ttl !== undefined ? (ttl * 1000) : 86400000)
        );
        delete domainParts[0];

        cookieOptions = {
            domain    : domainParts.join('.'),
            path      : '/',
            expiresAt : (session ? 'Session' : expiresAt)
        };

        try {
            if (session && typeof sessionStorage !== 'undefined') {
                sessionStorage[fullKey] = JSON.stringify(data);
            } else if (!session && typeof localStorage !== 'undefined') {
                localStorage[fullKey] = JSON.stringify(data);
            }
        } catch (err) {
            // ON error fallback to cookie if available...
            if (typeof $.cookies !== 'undefined') {
                $.cookies.set(fullKey, data, cookieOptions);
            }
        }

        return true;
    };

    WebStorageAdapter.prototype.del = function(namespace, key)
    {
        var fullKey = createKey(namespace, key),
            cleared = false;

        if (sessionKeyExists(fullKey)) {
            try {
                delete(sessionStorage[fullKey]);
            } catch (err) {
                sessionStorage[fullKey] = null;
            }
            cleared = true;
        }

        if (localKeyExists(fullKey)) {
            try {
                delete(localStorage[fullKey]);
            } catch (err) {
                localStorage[fullKey] = null;
            }
            cleared = true;
        }

        if (false === cleared) {
            $.cookies.del(fullKey);
            cleared = true;
        }

        return cleared;
    };

    WebStorageAdapter.prototype.clear = function(namespace)
    {
        var match = new RegExp('^' + namespace + '::'),
            i     = null,
            item  = null;

        for (i = 0; i < localStorage.length; i++) {
            item = localStorage[i];
            if (typeof item !== 'undefined' && item.match(match)) {
                try {
                    delete(localStorage[i]);
                } catch (err) {
                    localStorage[i] = null;
                }
            }
        }

        if (typeof sessionStorage === 'undefined') {
            return true;
        }

        for (i = 0; i < sessionStorage.length; i++) {
            item = sessionStorage[i];
            if (typeof item !== 'undefined' && item.match(match)) {
                try {
                    delete(sessionStorage[i]);
                } catch (err) {
                    sessionStorage[i] = null;
                }
            }
        }

        return true;
    };

    if (typeof window === 'object' && typeof window.document === 'object' && typeof window.storage === 'undefined') {
      window.storage = new WebStorageAdapter();
    }

}(jQuery));
