document.addEventListener('DOMContentLoaded', function() {
    var removeFavoriteContent = [
        '<a href="#" class="remove-from-favourites">',
        '<span class="icon card-remove-star-icon">',
        '<i class="fa fa-star"></i>',
        '</span>',
        '</a>'
    ].join('');

    var addFavoriteContent = [
        '<a href="#" class="add-to-favourites">',
        '<span class="icon card-star-icon">',
        '<i class="fa fa-star"></i>',
        '</span>',
        '</a>'
    ].join('');

    // Add / Remove as favorite
    var favorite = new window.Favorite(window.application);
    $('[data-cnt="search-result"]').on('click', '.button-addFavorite', function (e) {
        e.preventDefault();

        var $button = $(this);

        $.when(favorite.add($button.attr('rel'))).then(
            function () {
                $button.toggleClass('button-removeFavorite').toggleClass('button-addFavorite');
                $button.html(removeFavoriteContent);
            }
        );

        return false;
    });

    $('[data-cnt="search-result"]').on('click', '.button-removeFavorite', function (e) {
        e.preventDefault();

        var $button = $(this);

        $.when(favorite.remove($button.attr('rel'))).then(
            function () {
                $button.toggleClass('button-removeFavorite').toggleClass('button-addFavorite');
                $button.html(addFavoriteContent);
            }
        );

        return false;
    });
});
