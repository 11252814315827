document.addEventListener('DOMContentLoaded', function() {
    $('.contact_message_container').hide();

    $('.contact_subject').change(function(){
        if ($(this).val() == 'overige') {
            $('.contact_message_container').fadeIn(500);
        }
        else {
            $('.contact_message_container').hide();
        }
    });

    $('#contact-button').bind('click', function (evt) {
        evt.preventDefault();

        var btn = $(this),
        parent  = $(this).closest('.contact-container'),
        errorPlaceHolder = parent.find('.error-message');

        if($(btn).attr('disabled') == 'disabled') {
            return false;
        }

        data = {
            action: 'send',
            name: parent.find('.contact_name').val(),
            email: parent.find('.contact_email').val(),
            subject: parent.find('.contact_subject').val(),
            message: parent.find('.contact_message').val(),
            captcha: parent.find('.contact_captcha').val()
        };

        errorPlaceHolder.html('');
        errorPlaceHolder.slideUp('fast');
        $(btn).attr('disabled', 'disabled');

        $.when(window.application.callAjax('contact', 'send', data)).then(
        //success
        function(data) {
            parent.find('.form').slideUp('fast');
            if ($('.contact_subject').find(":selected").val()=='overige') {
                parent.find('.content_success_overige .contact_name').html(data.name);
                parent.find('.content_success_overige').slideDown('fast');
            }
            else {
                parent.find('.content_success_automatic .contact_name').html(data.name);
                parent.find('.content_success_automatic').slideDown('fast');
            }
        },
        //error
        function(errors) {
            if ($.isEmptyObject(errors)) {
                return false;
            }

            for (var i in errors) {
                errorPlaceHolder.html(window.application.options.contactErrors[errors[i].code]);
                break;
            }

            errorPlaceHolder.slideDown('fast');
            $(btn).removeAttr('disabled');
        });
    });
});
