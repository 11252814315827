document.addEventListener('DOMContentLoaded', function() {
    var dataNamespace = 'app.quickCredits',
        old              = $.fn.quickCredits,
        QuickCredits     = function (container, options) {
            this.cnt     = $(container);
            this.options = options;

            this.init();
        },
        intervalId = null,
        iteration  = 0,
        isCalling  = false,
        lastDate   = null;

    QuickCredits.defaults = {
        toggle : '[data-toggle="ppc"]',
        cnt    : '[data-cnt="ppc"]',
        option : {
            selector : '[data-ppc="option"]',
            tariff   : 'tariff',
            type     : 'type',
            cc       : 'cc'
        },
        credit : '[data-ppc="buy-credits"]',
        message : 'Tijdens dit gesprek hoeft u niks te doen, ' +
                  'de pincode wordt automatisch voor u ingevoerd. ' +
                  'De kosten zijn \u20AC {{tariff}} {{pp?Short}}.',
        ppmShort : 'p/m',
        ppcShort : 'p/g',
        maxIteration : 130,
        delay        : 1000
    };

    function checkStatus() {
        var self        = this,
            elapsedTime = null;

        if (isCalling === true) {
            return;
        }

        if (iteration > this.options.maxIteration) {
            stop();
            this.cnt.trigger('failure');
        }

        elapsedTime  = (new Date()).getTime() - lastDate.getTime();
        if (elapsedTime > this.options.delay) {
            iteration += Math.floor(elapsedTime / this.options.delay);
        } else {
            iteration++;
        }

        isCalling = true;
        window.application.callAjax(
            'ppc',
            'statusPPC'
        ).then(
            function(data) {
                isCalling = false;

                if (data.statusString === 'approved') {
                    stop();
                    self.cnt.trigger('success', [self.options.sender]);

                    return;
                }
            },
            function() {
                stop();
                self.cnt.trigger('failure');
            }
        );

        lastDate = new Date();
    }

    function stop() {
        if (intervalId === null) {
            return;
        }

        clearInterval(intervalId);
        intervalId = null;
    }

    function dial(number) {
        var self = this;

        // Stop ppc poll in progress
        stop();

        lastDate = new Date();

        intervalId = setInterval(
            function() { checkStatus.call(self); },
            this.options.delay
        );

        window.location = 'tel:' + number;
    };

    QuickCredits.prototype.init = function() {
        var self = this;

        $(this.options.toggle, this.cnt).click(function(e) {
            e.preventDefault();

            $(this).hide();
            $(self.options.cnt, self.cnt).show();
            $(self.options.option.selector, self.cnt).show();
        });

        $(this.options.credit, this.cnt).click(function(e) {
            e.preventDefault();

            window.storage.set('credits', 'redirect', window.location.href, true);
            window.location.href = '/credits';
        });

        this.cnt.on('success failure', function() {
            $(self.options.option.selector, self.cnt).removeClass('disabled');
        });

        $(this.options.option.selector, this.cnt).click(function(e) {
            e.preventDefault();

            var $this    = $(this),
                settings = self.options.option,
                tariff   = null,
                type     = null,
                cc       = null,
                message  = self.options.message;

            if ($this.hasClass('disabled')) {
                return;
            }

            $this.addClass('disabled');

            tariff  = $this.data(settings.tariff);
            type    = $this.data(settings.type);
            cc      = $this.data(settings.cc);
            message = message.replace('{{tariff}}', tariff);
            message = message.replace('{{pp?Short}}', (
                type === 'ppm' ?
                self.options.ppmShort :
                self.options.ppcShort
            ));

            if (!confirm(message)) {
                $this.removeClass('disabled');
                return;
            }

            window.application.callAjax(
                'ppc',
                'callMobilePPC',
                {
                    country_code : cc
                }
            ).then(
                function(data) {
                    if (data.number === undefined || '' === data.number) {
                        self.cnt.trigger('failure');
                        application.alert(application.options.quickCredits.errorTitle, application.options.quickCredits.errorMessage);
                        return;
                    }

                    dial.call(self, data.number);
                },
                function() {
                    self.cnt.trigger('failure');
                }
            );
        }).hide();
    };

    QuickCredits.prototype.show = function() {
        this.cnt.show();

        return this;
    };

    QuickCredits.prototype.hide = function() {
        this.cnt.hide();

        return this;
    };

    // Register plugin
    $.fn.quickCredits = function (option) {
        var callArgs = arguments;

        result = this.each(function() {
            var $this   = $(this),
                data    = null,
                options = null,
                args    = [];

            data    = $this.data(dataNamespace);
            options = $.extend(true, {}, QuickCredits.defaults, $this.data(), option);

            if (data === undefined) {
                $this.data(dataNamespace, (data = new QuickCredits(this, options)));
            }

            if (typeof option === 'string') {
                if (data[option] === undefined) {
                    throw 'Method ' + option + ' does not exists';
                }

                Array.prototype.push.apply(args, callArgs);
                args.shift();

                // call methods
                return data[option].apply(data, args);
            }
        });

        return result;
    };

    $.fn.quickCredits.Constructor = QuickCredits;

    // No conflict
    $.fn.quickCredits.noConflict = function () {
        $.fn.quickCredits = old;
        return this;
    };

});
