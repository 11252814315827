+(function () {
    var options = {},
        cnt = null;

    function Account(container, settings) {
        cnt = container;
        options = $.extend(true, {}, options, settings);
        this.loadCityXhr = null;
        this.selectedCity = {};

        init();
    }

    function init() {
        $.when(
            intializeDropdowns(),
            initializeLocation(),
            initializeDOB(),
            intializeGenericBindings(),
            initializeSaveBindings(),
            registerDeletePopup()
        ).then(function () {
            initializeValues();
            $('[maxlength]', cnt).trigger('change');
        });
    }

    function registerDeletePopup() {
        window.popup.register({
            key: 'delete',
            remoteTemplate: '/html/tpl/popup/delete-account.html',
            popupClasses: 'delete',
            variables: {
                beforeOpen: function ($cnt) {
                    if (application.environment.isIE() && application.environment.isMobile()) {
                        ('.overlay', $cnt).remove();
                    }
                },
                afterOpen: function ($cnt) {
                    $('.button', $cnt).click(function (e) {
                        e.preventDefault();

                        $('[data-cnt="error"]', $cnt).hide();

                        var deleteReason = null,
                            hasError = false,
                            errorMessage = null;


                        if ($('form :checkbox:checked').length === 0) {
                            hasError = true;
                            errorMessage = 'Je dient een verwijder reden op te geven.';
                        } else {
                            $('[data-field]', $cnt).each(function () {
                                var $this = $(this),
                                    value = $this.val();

                                $this.parent('div').removeClass('error');

                                if ($this.attr('type') === 'checkbox') {
                                    value = ($this.prop('checked') ? 1 : 0);
                                }

                                if ($this.is('textarea')) {

                                    var parent = $this.parent('div').siblings('input[type=checkbox]');

                                    if (parent.length > 0 && parent.prop('checked') === true) {

                                        if (value === '') {
                                            $this.parent('div').addClass('error');
                                            hasError = true;

                                            errorMessage = 'Je dient een tekst op te geven.';
                                            deleteReason = null;
                                            return false;
                                        }
                                        deleteReason += ":" + value;
                                    }
                                    return;
                                }
                                if (value === 1) {
                                    deleteReason = $this.data('field');
                                }
                            });
                        }

                        if (hasError === true) {
                            $('[data-cnt="error"]', $cnt).text(errorMessage).show('slow');
                            return;
                        }

                        window.application.callAjax('account', 'delete', {'deleteReason': deleteReason}).then(
                            function () {
                                window.location.href = '/removed';
                            },
                            function (errors) {
                                errorMessage = options.deleteErrorText;
                                $('[data-cnt="error"]', $cnt).text(errorMessage).show('slow');
                            }
                        );
                    });
                }
            }
        });
    }

    function intializeGenericBindings() {
        $('[maxlength]', cnt).on('keydown keyup change', function () {
            var $this = $(this),
                length = $this.val().length,
                maxLength = eval($this.attr('maxlength')),
                $counters = $('[data-counter="max-length"][data-for="' + $this.data('field') + '"]', $this.closest('[data-cnt]'));

            $this.removeClass('error');
            $counters.removeClass('out-of-boundary warning');

            if (maxLength - length < 0) {
                $this.addClass('error');
                $counters.addClass('out-of-boundary');
            } else if (maxLength - length <= 10) {
                $counters.addClass('warning');
            }

            $counters.html(maxLength - length);
        });

        $('[data-action="delete-account"]', cnt).click(function (e) {
            e.preventDefault();
            var $variableContainer = $('#remove-account-variables');
            if (
                confirm(
                    $variableContainer.data('note_strong') + ' ' +
                    $variableContainer.data('text_there_is_no_way_back') + "\n" +
                    $variableContainer.data('confirm_are_you_sure')
                ) === false
            ) {
                return;
            }

            window.application.callAjax('account', 'delete').then(
                function () {
                    window.location.href = '/removed';
                    return;
                },
                function () {
                    application.alert(options.errorTitle, options.unknownErrorText);
                }
            );
        });

        $('[data-group="upload"] input[type="file"]', cnt).on('change', function (e) {
            var $group = $(this).closest('[data-group="upload"]');

            $group.removeClass('error success');

            $('[data-field="filename"]', $group).html($(this).val().split(/(\/|\\)/g).pop());
        });

        $('[data-action="upload"]', cnt).click(function (e) {
            e.preventDefault();

            var $this = $(this),
                $cnt = $(this).closest('[data-cnt]'),
                inputs = [],
                i = 0,
                photoTpl = $('[data-tpl]', cnt).html();

            if ($this.hasClass('disabled')) {
                return;
            }

            $this.addClass('disabled');

            $('input[type="file"]', $cnt).each(function () {
                if ($(this).val() !== '') {
                    inputs.push('#' + $(this).attr('id'));
                }
            });

            for (i = 0; i < inputs.length; i++) {
                window.application.callAjaxUpload('account', 'photoUpload', inputs[i]).then(
                    function (result, id) {
                        $this.removeClass('disabled');

                        $(id).closest('[data-group="upload"]').addClass('success');

                        $('[data-field="filename"]', $cnt).text('');

                        $('[data-msg="success"]').show(function () {
                            setTimeout(function () {
                                $('[data-msg="success"]').hide();
                            }, 2000);

                            $("html, body").animate({
                                scrollTop: 0
                            }, 600);
                        });

                        photoTpl = photoTpl.replace('{{path}}', result.data.path);
                        photoTpl = photoTpl.replace(/{{id}}/g, result.data.id);

                        $('[data-title="upload"]').before(photoTpl);
                        $('[data-title="upload"]').trigger('change');
                    },
                    function (response, id) {
                        var message = options.upload_errors[response.errors[0].code] || response.errors[0].desc;

                        $this.removeClass('disabled');

                        $('[data-msg="error-photo-msg"] p').html(message);
                        $('[data-msg="error-photo-msg"]').show(function () {
                            setTimeout(function () {
                                $('[data-msg="error-photo-msg"]').hide();
                            }, 2000);

                            $("html, body").animate({
                                scrollTop: 0
                            }, 600);
                        });
                    }
                );
            }
        });

        $(cnt).delegate('[data-action="delete-photo"]', 'click', function () {
            var $this = $(this),
                photoId = $(this).attr('data-id'),
                isSplash = $(this).attr('data-splash');

            if (confirm(window.application.options.myProfile.deletePhotoConfirm)) {
                window.application.callAjax('account', 'photoRemove', {id: photoId}).then(
                    function () {
                        if (isSplash != 1) {
                            $('[data-photo="' + photoId + '"]', cnt).remove();
                            $('[data-title="upload"]').trigger('change');

                            $('[data-msg="success-photo-delete"]', options.updatecontainer).show(function () {
                                setTimeout(function () {
                                    $('[data-msg="success-photo-delete"]', options.updatecontainer).hide();
                                }, 2000);

                                $("html, body").animate({
                                    scrollTop: 0
                                }, 600);
                            });
                        } else {
                            $('[data-msg="success-photo-delete"]', options.updatecontainer).show(function () {
                                setTimeout(function () {
                                    location.reload();
                                }, 2000);

                                $("html, body").animate({
                                    scrollTop: 0
                                }, 600);
                            });

                        }
                    },
                    function (error) {
                        alert('Systemfout');
                    }
                );
            }
        });

        $('[data-close="popup"]', cnt).click(function () {
            $('[data-popup="photo"]', cnt).hide();
        });

        $(cnt).delegate('[data-action="profile-photo"]', 'click', function () {
            var $this = $(this),
                photoId = $(this).attr('data-photo'),
                $photoUploadVariables = $('#photo-upload-variables'),
                //New Splash Picture
                photo = $(this).closest('.picture'),
                photoSrc = photo.find('.file-img img').attr('src'),
                //Currenct Splash Picture
                actualPhoto = $('[data-photo="profile-photo"]', cnt).closest('.picture'),
                actualId = actualPhoto.closest('.picture').data('photo'),
                span = '<span data-photo="profile-photo">' + $photoUploadVariables.data('text_my_profile_photo') + '</span>',
                link = '<a data-action="profile-photo" data-photo="' + actualId + '">' + $photoUploadVariables.data('text_make_profile_photo') + '</a>';

            window.application.callAjax('account', 'photoMakeSplash', {id: photoId}).then(
                function (response) {
                    $('[data-profileimg]').attr('src', photoSrc);
                    location.reload();
                    $("html, body").animate({
                        scrollTop: 0
                    }, 600);

                    $('[data-msg="success"]', options.updatecontainer).show(function () {
                        setTimeout(function () {
                            $('[data-msg="success"]', options.updatecontainer).hide();
                        }, 2000);
                    });

                },
                function (error) {
                    $('[data-msg="error-photo"]', options.updatecontainer).show(function () {
                        setTimeout(function () {
                            $('[data-msg="error-photo"]', options.updatecontainer).hide();
                        }, 2000);
                    });

                    $("html, body").animate({
                        scrollTop: 0
                    }, 600);
                }
            );
        });

    }

    function singleFieldChangeUsed(postData, field) {
        var message = options.genderModifiedText,
            confirmChange = false,
            genderBool = (
                options.genderChangeUsed !== undefined &&
                options.genderChangeUsed === false &&
                options.profile.gender !== undefined &&
                postData['gender'] !== undefined &&
                postData['gender'] !== options.profile.gender
            ),
            birthDateBool = (
                options.birthDateChangeUsed !== undefined &&
                options.birthDateChangeUsed === false &&
                options.profile.dateOfBirth !== undefined &&
                postData['dateOfBirth'] !== undefined &&
                postData['dateOfBirth'] !== options.profile.dateOfBirth
            );


        if (genderBool === true || birthDateBool === true) {
            confirmChange = confirm(message);
        }

        if (genderBool === true && confirmChange === true) {
            postData['genderChangeUsed'] = true;
            options.profile.gender = postData.gender;

        } else {
            var $gender = $('[data-field="gender"]', cnt);
            $gender.val(options.profile.gender);
            postData['gender'] = options.profile.gender;
        }

        if (birthDateBool === true && confirmChange === true) {
            postData['birthDateChangeUsed'] = true;
            options.profile.dateOfBirth = postData.dateOfBirth;

        } else {
            $day = $('[data-field="dateOfBirth.day"]', cnt);
            $month = $('[data-field="dateOfBirth.month"]', cnt);
            $year = $('[data-field="dateOfBirth.year"]', cnt);

            initialBirthDateParts = options.profile.dateOfBirth.split('-');
            $day.val(initialBirthDateParts[2].replace(/^0+/, ''));
            $month.val(initialBirthDateParts[1].replace(/^0+/, ''));
            $year.val(initialBirthDateParts[0]);

            postData['dateOfBirth'] = options.profile.dateOfBirth;
        }
        return postData
    }

    function updateSearchPreferences(postData) {

        var geoFilter = $('[data-field="matchRegion"] option:selected').data('filter') || {};

        postData.geoIso      = (typeof geoFilter['iso'] !== 'undefined') ? geoFilter.iso : 'all';
        postData.geoLanguage = (typeof geoFilter['language'] !== 'undefined') ? geoFilter.language : 'all';
        postData.gender = postData.matchGender;
        postData.geoRegion1 = postData.matchRegion;
        postData.geoRegion2 = (typeof geoFilter['region2'] !== 'undefined') ? geoFilter.region2 : 'all';
        postData.geoRegion3 = (typeof geoFilter['region3'] !== 'undefined') ? geoFilter.region3 : 'all';
        postData.geoRegion4 = (typeof geoFilter['region4'] !== 'undefined') ? geoFilter.region4 : 'all';
        postData.geoPostcode = (typeof geoFilter['postcode'] !== 'undefined') ? geoFilter.postcode : 'all';
        postData.geoLocality = (typeof geoFilter['locality'] !== 'undefined') ? geoFilter.locality : 'all';
        postData.geoTimezone = (typeof geoFilter['timezone'] !== 'undefined') ? geoFilter.timezone : 'all';


        window.application.callAjax('account', 'setSearch', postData).then(
            function () {
            });
    }

    function updateMyProfile() {

        var aboutYouValues = getFieldValues($('[data-cnt="about-you"]'));
        var generalInfoValues = getFieldValues($('[data-cnt="general-info"]'));
        var personalInfoValues = getFieldValues($('[data-cnt="personal-info"]'));
        var searchPreferencesValues = getFieldValues($('[data-cnt="search-preferences"]'))

        var postData = Object.assign(aboutYouValues, generalInfoValues, personalInfoValues);
        var matchPostData = Object.assign(searchPreferencesValues);

        // When there is no change of city, don't post geo
        if (options.profile.geoLocality !== generalInfoValues.city) {
            var geoData = geoDataService.getInstance();
            postData.geo = geoData.getSelectedPlaceObject();
        }

        if (options.birthDateChangeUsed === false || options.genderChangeUsed === false) {
            postData = singleFieldChangeUsed(postData);
        }

        window.application.callAjax('account', 'update', postData).then(
            function (response) {
                if (response !== undefined) {
                    if (response.birthDateChangeUsed === true) {
                        $(
                            '[data-field="dateOfBirth.day"],' +
                            ' [data-field="dateOfBirth.month"],' +
                            ' [data-field="dateOfBirth.year"]',
                            cnt
                        ).prop('disabled', 'disabled').removeClass('pointer-event').addClass('disabled-pointer-event');
                        ;
                    }
                    if (response.genderChangeUsed === true) {
                        $('[data-field="gender"]', cnt).prop('disabled', 'disabled').removeClass('pointer-event').addClass('disabled-pointer-event');
                    }
                }
            })

        updateSearchPreferences(matchPostData);
    }

    function getFieldValues(contentBlock) {
        var values = {};

        $('[data-field]', contentBlock).each(function () {
            var $this = $(this);
            values[$this.data('field')] = $this.val();
        });

        // Restructure date of birth for the backend.
        if (
            values['dateOfBirth.day'] !== undefined &&
            values['dateOfBirth.month'] !== undefined &&
            values['dateOfBirth.year'] !== undefined
        ) {
            values['dateOfBirth'] =
                values['dateOfBirth.year'] +
                '-' +
                values['dateOfBirth.month'].padLeft(2, '0') +
                '-' +
                values['dateOfBirth.day'].padLeft(2, '0');

            delete values['dateOfBirth.year'];
            delete values['dateOfBirth.month'];
            delete values['dateOfBirth.day'];
        }

        return values;
    }

    function initializeSaveBindings() {

        var $saveBtn = $('[data-action="save"]', cnt);

        $saveBtn.click(function (e) {
            e.preventDefault();
            var hasErrors = false;

            updateMyProfile();

            $saveBtn.each(function (e) {
                var $cnt = $(this).closest('[data-cnt]'),
                    postData = {},
                    hasError = false;

                $cnt.removeClass('error');
                $('[data-msg="success"]', $cnt).hide();
                $('[data-msg="error"]', $cnt).hide();

                $('[data-field]', $cnt).each(function () {
                    var $this = $(this),
                        value = $this.val();

                    if ($this.attr('type') === 'checkbox') {
                        value = ($this.prop('checked') ? 1 : 0);
                    }

                    if ($this.attr('type') === 'radio') {
                        if ($this.prop('checked')) {
                            value = $this.val()
                        } else {
                            return;
                        }
                    }

                    $this.removeClass('error');

                    if ($this.data('required') === true && value === '') {
                        $this.addClass('error');
                        hasError = true;

                        return;
                    }

                    postData[$this.data('field')] = value;
                });

                if (hasError === true) {
                    hasErrors = true;
                    return;
                }

                switch ($cnt.data('action')) {
                    case 'preferences':
                        window.application.callAjax('account', 'updatePreferences', {preferences: postData}).then(
                            function () {
                            },
                            function () {
                                hasErrors = true;
                            }
                        );
                        break;
                    case 'password':
                        if (postData['new_password'] !== postData['new_password_repeat']) {

                            $('[data-msg="invalid_repeat"]').show(function () {
                                setTimeout(function () {
                                    $('[data-msg="invalid_repeat"]').hide();
                                }, 2000);
                            });

                            $("html, body").animate({
                                scrollTop: 0
                            }, 600);
                            return;
                        }

                        window.application.callAjax('account', 'changePassword', postData).then(
                            function () {
                                $('input, textarea, select', $cnt).val('');

                                $('[data-msg="success"]').show(function () {
                                    setTimeout(function () {
                                        $('[data-msg="success"]').hide();
                                    }, 3000);
                                });

                                $("html, body").animate({
                                    scrollTop: 0
                                }, 600);
                            },
                            function (errors) {
                                var oldMessage = $('[data-msg="error_password"]').html();

                                $('.password-error-block').show();
                                $('[data-msg="error_password"]')
                                    .html(errors[0].desc)
                                    .show(function () {
                                        setTimeout(function () {
                                            $('[data-msg="error_password"]').html(oldMessage).hide();
                                            $('.password-error-block').hide();
                                        }, 3000);
                                    });
                                $("html, body").animate({
                                    scrollTop: 0
                                }, 600);
                            }
                        );
                        break;
                    case 'interests':
                        window.application.callAjax('account', 'updateInterests', {interests: postData}).then(
                            function () {
                            },
                            function () {
                                hasErrors = true;
                            }
                        );
                        break;
                }
            });

            if (hasErrors === false) {

                $('[data-msg="success"]', options.updatecontainer).show(function () {
                    setTimeout(function () {
                        $('[data-msg="success"]', options.updatecontainer).hide();
                    }, 2000);
                });

                $("html, body").animate({
                    scrollTop: 0
                }, 600);

            } else {

                $('[data-msg="error"]').show(function () {
                    setTimeout(function () {
                        $('[data-msg="error"]').hide();
                    }, 5000);
                });

                $("html, body").animate({
                    scrollTop: 0
                }, 600);

            }
        });


        $('[data-action="savePassw"]', cnt).click(function (e) {
            e.preventDefault();

            var $cnt = $(this).closest('[data-cnt]'),
                postData = {},
                hasError = false;

            $cnt.removeClass('error');
            $('[data-msg="success"]', $cnt).hide();
            $('[data-msg="error"]', $cnt).hide();

            $('[data-field]', $cnt).each(function () {
                var $this = $(this),
                    value = $this.val();

                if ($this.attr('type') === 'checkbox') {
                    value = ($this.prop('checked') ? 1 : 0);
                }

                if ($this.attr('type') === 'radio') {
                    if ($this.prop('checked')) {
                        value = $this.val()
                    } else {
                        return;
                    }
                }

                $this.removeClass('error');

                if ($this.data('required') === true && value === '') {
                    $this.addClass('error');
                    hasError = true;
                    return;
                }

                postData[$this.data('field')] = value;
            });

            if (hasError === true) {
                hasErrors = true;
                return;
            }

            switch ($cnt.data('action')) {
                case 'password':
                    if (postData['new_password'] !== postData['new_password_repeat']) {
                        $('[data-msg="invalid_repeat"]').show(function () {
                            setTimeout(function () {
                                $('[data-msg="invalid_repeat"]').hide();
                            }, 2000);
                        });

                        $("html, body").animate({
                            scrollTop: 0
                        }, 600);

                        return;
                    }

                    window.application.callAjax('account', 'changePassword', postData).then(
                        function () {
                            $('input, textarea, select', $cnt).val('');

                            $('[data-msg="success"]').show(function () {
                                setTimeout(function () {
                                    $('[data-msg="success"]').hide();
                                }, 3000);
                            });

                            $("html, body").animate({
                                scrollTop: 0
                            }, 600);
                        },
                        function (errors) {
                            var oldMessage = $('[data-msg="error_password"]').html();

                            $('.password-error-block').show();
                            $('[data-msg="error_password"]').html(errors[0].desc)
                                .show(function () {
                                    setTimeout(function () {
                                        $('[data-msg="error_password"]').html(oldMessage).hide();
                                        $('.password-error-block').hide();
                                    }, 3000);
                                });
                            $("html, body").animate({
                                scrollTop: 0
                            }, 600);
                        }
                    );
                    break;
            }
        });
    }


    function intializeDropdowns() {
        var cnt = $('[data-cnt="details"]'),
            mapping = options.dropdownMapping,
            i,
            j,
            $field,
            choices,
            value;

        for (i in options.dropdowns) {
            $field = $('[data-field="' + options.dropdowns[i].id + '"]', cnt);
            value = options.profile[options.dropdowns[i].id];
            choices = options.dropdowns[i].options;

            $('[data-field="gender"]').val(options.profile.gender);

            if ($field[0]) {
                if (value === null || value === '') {
                    $field.append('<option value=""> -- ' + options.chooseLabel + ' -- </option>');
                }

                choices.forEach(function(choice) {
                    $field.append('<option value="' + choice.id + '">' + mapping[options.dropdowns[i].id][choice.id] + '</option>');
                })
            }
        }
    }

    function initializeDOB() {
        var $day = $('[data-field="dateOfBirth.day"]', cnt),
            $month = $('[data-field="dateOfBirth.month"]', cnt),
            $year = $('[data-field="dateOfBirth.year"]', cnt),
            now = new Date(),
            i = null,
            preventTrigger = false,
            selected = null,
            dateOfBirth = (options.profile.dateOfBirth ? options.profile.dateOfBirth.split('-') : ['', '', '']);

        for (i = 1; i <= 31; i++) {
            selected = (i == dateOfBirth[2]) ? ' selected="selected"' : '';
            $day.append('<option value="' + i + '"' + selected + '>' + i + '</option>');
        }

        for (i = 1; i <= 12; i++) {
            selected = (i == dateOfBirth[1]) ? ' selected="selected"' : '';
            $month.append('<option value="' + i + '"' + selected + '>' + i + '</option>');
        }

        for (i = now.getFullYear() - 18; i >= now.getFullYear() - 99; i--) {
            selected = (i == dateOfBirth[0]) ? ' selected="selected"' : '';
            $year.append('<option value="' + i + '"' + selected + '>' + i + '</option>');
        }

        $('[data-field="dateOfBirth.day"], [data-field="dateOfBirth.month"], [data-field="dateOfBirth.year"]', cnt).on('change', function (e) {
            if (preventTrigger === true) {
                return;
            }

            var year = eval($year.val()),
                month = eval($month.val()),
                day = eval($day.val()),
                maxDay = null,
                maxMonth = 12;

            preventTrigger = true;

            if (year !== undefined) {
                if (year === new Date().getFullYear() - 18) {
                    maxMonth = new Date().getMonth() + 1;

                    if (month === maxMonth) {
                        maxDay = new Date().getDate();
                    }
                }

                for (i = 1; i <= 12; i++) {
                    if (i > maxMonth) {
                        if (getIE() === false) {
                            $('option[value="' + i + '"]', $month).prop('disabled', true);
                        } else {
                            $('option[value="' + i + '"]', $month).hide();
                        }
                    } else {
                        if (getIE() === false) {
                            $('option[value="' + i + '"]', $month).prop('disabled', false);
                        } else {
                            $('option[value="' + i + '"]', $month).show();
                        }
                    }
                }
            }

            if (month > maxMonth) {
                $month.val('');
            }

            if (maxDay !== null || (year !== undefined && month !== undefined)) {
                if (maxDay === null) {
                    maxDay = new Date(year, month, 0).getDate();
                }

                if (day !== undefined && maxDay < day) {
                    $day.val('');
                }

                for (i = 1; i <= 31; i++) {
                    if (i > maxDay) {
                        if (getIE() === false) {
                            $('option[value="' + i + '"]', $day).prop('disabled', true);
                        } else {
                            $('option[value="' + i + '"]', $day).hide();
                        }
                    } else {
                        if (getIE() === false) {
                            $('option[value="' + i + '"]', $day).prop('disabled', false);
                        } else {
                            $('option[value="' + i + '"]', $day).show();
                        }
                    }
                }
            }

            preventTrigger = false;
        });
    }

    function initializeValues() {
        var cnt = $('[data-cnt="details"]'),
            i,
            $field;

        for (i in options.profile) {
            $field = $('[data-field="' + i + '"]', cnt);
            $field.val(options.profile[i]);

            if ($.isFunction($.fn.resetSS) == true) {
                if (i == 'town') {
                    continue;
                }
                if (i == 'region') {
                    continue;
                }
                if (i == 'country') {
                    continue;
                }
                $($field).resetSS();
            }
        }
    }

    function getIE() {
        var agent = navigator.userAgent.toLowerCase();

        return (agent.indexOf('msie') != -1) ? parseInt(agent.split('msie')[1]) : false;
    }

    function initializeLocation() {

        // Create geoDataService Instance and init geoData..
        var geoData = geoDataService.getInstance();
        var config = {
            structure: "autoSuggestCity",
            fields: {
                "postcode":  $("[data-field='postcode']"),
                "city"  :   $("[data-field='city']")
            },
            initialValues: {
                postcode: options.profile.geoPostcode,
                city: options.profile.geoLocality,
            }
        };
        geoData.initGeo(config);
    }

    this.Account = Account;
}).call(this);
