document.addEventListener('DOMContentLoaded', function() {
    $('[data-popup-open]').on('click', function() {
        var id = $(this).data('popup-ref');

        //put the current clicked modal in a jquery variable
        var $clickedPopup = $('[data-popup-id="' + id + '"]');

        //add the is-active class to display the modal
        $clickedPopup.addClass('is-active');

        //if the current clicked modal has a input field execute the ios fix open function.
        if ($clickedPopup.find('input').length) {
            window.safariModalOpenFix($clickedPopup);
        }
    });

    // close modal
    $('.modal-close').click(function() {

        //put the clicked modal in a jquery variable
        var $clickedPopup = $(this).parents('.modal');

        //if the clicked modal contains a input field execute the ios fix close function
        if ($clickedPopup.find('input').length) {
            window.safariModalCloseFix($clickedPopup);
        }

        //remove the is-active class to close the modal
        $clickedPopup.removeClass('is-active');

        // remove loader and disable state from the send message submit button
        $('[data-conversation="send"]').removeClass('is-loading').removeAttr('disabled');
    });

    $('.swipebox-overlay').on('click', function (e) {
        e.stopPropagation();
    });

    $('[data-nav="navlink"]').on('click', function (e) {
        e.stopPropagation();
    });

    // init font calculator services
    window.application.service.autoDecreaseFontSize();
    window.application.service.autoIncreaseFontSize();
});
