(function() {
    // ios11 safari bug fixes
    var userAgent = window.navigator.userAgent.toLowerCase();
    var isIos = /iphone/.test(userAgent);

    window.safariModalOpenFix = function($clickedPopup) {
        //if you are on iphone IOS add classes to the body and the clicked popup
        if (isIos) {
            $(document.body).addClass('ios-input-caret-fix--body');
            $($clickedPopup).addClass('ios-input-caret-fix--modal');
        }
    };

    window.safariModalCloseFix = function($clickedPopup) {
        //if you are on iphone IOS remove classes from the body and the clicked popup
        if (isIos) {
            $(document.body).removeClass('ios-input-caret-fix--body');
            $($clickedPopup).removeClass('ios-input-caret-fix--modal');
        }
    };
}());
