(function($) {
    var dataNamespace = 'app.register',
        old   = $.fn.register,
        Register = function (container, application, options) {
            var self = this;
            this.cnt = $(container);

            this.options         = options;
            this.application     = application;
            this.loadCityXhr     = null;
            this.singleSignOn = checkSingleSignOnUsage();
            if (this.singleSignOn) handleSingleSignOnFields();

            init.call(this);
        };

    Register.defaults = {
        errors : {
            'clientname_empty'                : 'Je hebt geen gebruikersnaam ingevuld.',
            'passw_empty'                     : 'Je hebt geen wachtwoord ingevuld.',
            'dateOfBirth.day_empty'           : 'Je hebt geen geboortedag ingevuld.',
            'dateOfBirth.month_empty'         : 'Je hebt geen geboortemaand ingevuld.',
            'dateOfBirth.year_empty'          : 'Je hebt geen geboortejaar ingevuld.',
            'email_empty'                     : 'Je hebt geen e-mailadres ingevuld',
            'conditions-accepted_not_checked' : 'Je moet de voorwaarden accepteren.',
            'gender_empty' 		            : 'Je moet een geslacht kiezen.',
        },
        labels : {
            'choose_region_first'           : 'Kies eerst een provincie',
            'choose_town'                     : 'Kies je town'
        },
    };

    function checkSingleSignOnUsage() {
        //get querystring
        const queryString = window.location.search;

        //get searchparams
        const urlParams = new URLSearchParams(queryString);

        //return the sso value
        const returnValue = urlParams.get('sso');

        return returnValue
    }

    function handleSingleSignOnFields() {
        $('[data-field="email"]').hide().val('googlesso@gmail.com');
        $('[data-field="passw"]').hide().val('1234567989');
        $('[data-google-register-button]').hide();
        $('[data-google-sso-hide]').hide();
        $('[data-popup-id="register-splash"]').addClass('is-active');
    }

    function getIE() {
        var agent = navigator.userAgent.toLowerCase();

        return (agent.indexOf('msie') != -1) ? parseInt(agent.split('msie')[1]) : false;
    }

    function init()
    {
        var self   = this,
            $day   = $('[data-field="dateOfBirth.day"]', this.cnt),
            $month = $('[data-field="dateOfBirth.month"]', this.cnt),
            $year  = $('[data-field="dateOfBirth.year"]', this.cnt),
            now    = new Date(),
            i      = null,
            preventTrigger = false;

        function disableBtn($btn) {
            $btn.addClass('is-loading').attr('disabled', 'disabled');
        }

        function enableBtn($btn) {
            $btn.removeClass('is-loading').removeAttr('disabled');
        }

        $('[data-register="confirm"]', this.cnt).click(function(e) {
            var $this = $(this);

            e.preventDefault();

            if ($this.attr('disabled') === 'disabled') {
                return;
            }

            disableBtn($this);

            signup.call(self, true).then(
                function() {
                    enableBtn($this);
                    $('[data-form]', self.cnt).hide();
                    $('[data-confirm]', self.cnt).show();
                    $('[data-givenname]', '[data-confirm]').text($('[data-field="clientname"]', self.cnt).val());
                    $('[data-givenpass]', '[data-confirm]').text($('[data-field="passw"]', self.cnt).val());
                    $('[data-givenemail]', '[data-confirm]').text($('[data-field="email"]', self.cnt).val());
                },
                function() {
                    enableBtn($this);
                }
            );
        });

        $('[data-register="submit"]', this.cnt).on('click', function(e) {
            e.preventDefault();

            var $this = $(this);

            if (application.control.button.lock($this) === false) {
                return;
            }

            signup.call(self).then(
                function() {
                    application.control.button.unlock($this);
                },
                function() {
                    application.control.button.unlock($this);
                }
            );
        });

        var daysTmpl = '';
        var monthsTmpl = '';
        var yearsTmpl = '';

        for (i = 1; i <= 31; i++) {
            daysTmpl += '<option value="' + i + '">' + i + '</option>';
        }
        $day.append(daysTmpl);

        for (i = 1; i <= 12; i++) {
            monthsTmpl += '<option value="' + i + '">' + i + '</option>';
        }
        $month.append(monthsTmpl);

        for (i = now.getFullYear() - 18; i >= now.getFullYear() - 99; i--) {
            yearsTmpl += '<option value="' + i + '">' + i + '</option>';
        }
        $year.append(yearsTmpl);

		if ($.isFunction($.fn.resetSS) == true) {
			$day.resetSS();
			$month.resetSS();
			$year.resetSS();
		}

        $('[data-field="dateOfBirth.day"], [data-field="dateOfBirth.month"], [data-field="dateOfBirth.year"]', this.cnt).on('change', function(e) {
            if (preventTrigger === true) {
                return;
            }

            var year     = eval($year.val()),
                month    = eval($month.val()),
                day      = eval($day.val()),
                maxDay   = null,
                maxMonth = 12;

            preventTrigger = true;

            if (year !== undefined) {
                if (year === new Date().getFullYear() - 18) {
                    maxMonth = new Date().getMonth() + 1;

                    if (month === maxMonth) {
                        maxDay   = new Date().getDate();
                    }
                }

                for (i = 1; i <= 12; i++) {
                    if (i > maxMonth) {
                        if (getIE() === false) {
                            $('option[value="' + i + '"]', $month).prop('disabled', true);
                        } else {
                            $('option[value="' + i + '"]', $month).hide();
                        }
                    } else {
                        if (getIE() === false) {
                            $('option[value="' + i + '"]', $month).prop('disabled', false);
                        } else {
                            $('option[value="' + i + '"]', $month).show();
                        }
                    }
                }

            }

            if (month > maxMonth) {
                $month.val('');
            }

            if (maxDay !== null || (year !== undefined && month !== undefined)) {
                if (maxDay === null) {
                    maxDay = new Date(year, month, 0).getDate();
                }

                if (day !== undefined && maxDay < day) {
                    $day.val('');
                }

                for (i = 1; i <= 31; i++) {
                    if (i > maxDay) {
                        if (getIE() === false) {
                            $('option[value="' + i + '"]', $day).prop('disabled', true);
                        } else {
                            $('option[value="' + i + '"]', $day).hide();
                        }
                    } else {
                        if (getIE() === false) {
                            $('option[value="' + i + '"]', $day).prop('disabled', false);
                        } else {
                            $('option[value="' + i + '"]', $day).show();
                        }
                    }
                }
            }

			if ($.isFunction($.fn.resetSS) == true) {
				$day.resetSS();
				$month.resetSS();
				$year.resetSS();
			}

            preventTrigger = false;
        });

    }

    function signup(onlyCheck)
    {
        var self            = this,
            postData        = {},
            matchData       = {},
            errors          = [],
            errorFields     = [],
            errorMessages   = [],
            deferred        = new $.Deferred;

        this.cnt.removeClass('has-error');
        $('[data-cnt="error"]', self.cnt).html('');

        function setValue(key, value) {
            var path    = null,
                section = null,
                i       = null,
                tmp     = postData;

            path = key.split('.');

            for (i = 0; i < path.length - 1; i++) {
                if (tmp[path[i]] === undefined) {
                    tmp[path[i]] = {};
                }

                tmp = tmp[path[i]];
            }

            tmp[path[i]] = value;
        }

        function displayErrorsInline(errorFields) {
            errorFields.forEach(function(element, i) {

                var inputWrapper = element.closest('.control');
                inputWrapper.find('.help').remove();
                inputWrapper.children(':first').removeClass('is-danger');

                inputWrapper.append('<p class="help notification-error is-danger margin-top-10">' + self.options.errors[errorMessages[i]] + '</p>');
                inputWrapper.children(':first').addClass('is-danger');

                element.on('click', function() {
                    inputWrapper.find('.help').remove();
                    inputWrapper.children(':first').removeClass('is-danger');
                });
            });
        }

        function displayErrors(input) {
            var i   = null,
                cnt = $('[data-cnt="error"]', self.cnt),
                ul = '<ul class="notification is-danger margin-bottom-20">';
            for (i = 0; i < input.length; i++) {

                if (typeof self.options.errors[input[i]] === 'undefined') {
                    continue;
                }

                ul += '<li>' + self.options.errors[input[i]] + '</li>';
            }

            self.cnt.addClass('has-errors');

            cnt.html(ul).show();
            $("html, body").animate({
                scrollTop: 0
            }, 600);
        }

        $('[data-field]', this.cnt).each(function() {
            var $this   	= $(this),
				radiovalue	= null,
				errorKey	= null;
            if ($this.attr('required') && ($this.val() === null || $this.val() === '')) {
                errorFields.push($this);
                errorMessages.push($this.data('field') + '_empty');
                return;
            }

            if ($this.attr('type') === 'checkbox' && $this.prop('checked') === false) {
                errorFields.push($this);
                errorMessages.push($this.data('field') + '_not_checked');
                return;
            }

			if ($this.attr('type') === 'radio') {
				radiovalue = $('[data-field="'+$this.data('field')+'"]:checked').val();
                if (typeof radiovalue == 'undefined') {
					errorKey = $this.data('field') + '_not_checked';
					if ($.inArray(errorKey, errorMessages) == -1) {
                        errorFields.push($this);
                        errorMessages.push(errorKey);
					}
				} else {
					setValue($this.data('field'), radiovalue);
				}
                return;
            }

            setValue($this.data('field'), $this.val());
        });

        if (errorFields.length > 0) {
            displayErrorsInline(errorFields);
            deferred.reject();

            return deferred.promise();
        }

        postData.dateOfBirth = postData.dateOfBirth.year + '-' + postData.dateOfBirth.month.padLeft(2, '0') + '-' + postData.dateOfBirth.day.padLeft(2, '0');
        postData['conditions-accepted'] = $('[data-conditions]').is(':checked');
        postData.countryCode = $('option:checked', '[data-field="region"]').data('countrycode');

        if ($('[data-match="gender"]', this.cnt).length > 0) {
            matchData.gender = $('[data-match="gender"]', this.cnt).val();
        }
		if (typeof matchData.country == 'undefined') {
			matchData.country = postData.countryCode
		}
		if (typeof matchData.region == 'undefined') {
			matchData.region = postData.region
		}
		if (typeof matchData.gender == 'undefined') {
			matchData.gender = $('[data-match="gender"]:checked').val();
            if (typeof matchData.gender == 'undefined') {
				matchData.gender = 'female';
			}
		}

        postData.search = matchData;

        if (onlyCheck === true) {
            return deferred.resolve();
        }

        $('[data-register="submit"]').toggleClass('is-loading');

        var geoData = geoDataService.getInstance();
        postData.geo = geoData.getSelectedPlaceObject();

        // //add sso if there
        if (self.singleSignOn) {
            postData.sso = self.singleSignOn
        }

        bootstrap.onCollectConsentReady.then(function () {
            // Grab the user's session id, and add it to the postData
            if (typeof CollectConsent !== 'undefined') {
                postData.collectConsent = {
                    sId : CollectConsent.getCore().getSessionUserId()
                }
            }
        });

        application.callAjax('auth', 'register', postData).then(
            function(data) {
                var postFunction = function() {
                    if (self.singleSignOn) {
                        window.location.href = '/members#first-log'
                    } else {
                        $('[data-cnt="login"] [data-field="username"]').val(postData.email);
                        $('[data-cnt="login"] [data-field="password"]').val(postData.passw);
                        $('[data-cnt="login"] [data-toggle="login"]').trigger('click');
                    }

                    deferred.resolve();
                };

                if (data && data.htmlEvents && typeof data.htmlEvents.forEach === 'function') {
                    data.htmlEvents.forEach(function(event) {
                        $('body').append(event.htmlCode);
                    });

                    window.setTimeout(postFunction, 1000);

                    return;
                }

                postFunction();
            },
            function(errors)
            {
                var list = [],
                    i    = 0;

                deferred.reject();

                $('[data-register="submit"]').toggleClass('is-loading');

                for (i = 0; i < errors.length; i++) {
                    if (errors[i].code === 'accept_conditions') {
                        errors[i].code = 'conditions-accepted_not_checked';
                    }

                    list.push(errors[i].code);

                    if (errors[i].code === 'clientid_unavailable') {
                        $('[data-field="clientname"]', self.cnt).val(errors[i].field);
                    }

                    if (errors[i].code === 'clientname_too_short') {
                        $('[data-field="clientname"]', self.cnt).addClass('is-danger');
                    }

                    if (errors[i].code === 'password_too_short') {
                        $('[data-field="passw"]', self.cnt).addClass('is-danger');
                    }

                    if (errors[i].code === 'email_invalid') {
                        $('[data-field="email"]', self.cnt).addClass('is-danger');
                    }

                    if (self.options.errors[errors[i].code] === undefined) {
                        self.options.errors[errors[i].code] = errors[i].desc;
                    }
                }

                displayErrors(list);

                if (errors.length === 1 && errors[0].code === 'conditions-accepted_not_checked') {
                    return;
                }

                $('[data-confirm]', self.cnt).hide();
                $('[data-form]', self.cnt).show();
            }
        );

        return deferred.promise();
    }

    // register plugin
    $.fn.register = function (application, option) {
        var callArgs = arguments,
            result = this.each(function() {
                var $this   = $(this),
                    data    = null,
                    options = null,
                    args    = [];

                data    = $this.data(dataNamespace);
                options = $.extend(true, {}, Register.defaults, $this.data(), option);

                if (data === undefined) {
                    $this.data(dataNamespace, (data = new Register(this, application, options)));
                }

                if (typeof option === 'string') {
                    if (data[option] === undefined) {
                        throw 'Method ' + option + ' does not exists';
                    }

                    Array.prototype.push.apply(args, callArgs);
                    args.shift();

                    // call methods
                    return data[option].apply(data, args);
                }
            });
        return result;
    };

    $.fn.register.Constructor = Register;

    // No conflict
    $.fn.register.noConflict = function () {
        $.fn.register = old;
        return this;
    };

}(jQuery, window));
