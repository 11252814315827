$(function() {
    var $menu = $('[data-nav-dropdown="menu"]'),
        ofsetBar = 5; // 5 is to account for an ofset which creates a 5px bar effect. If not there, the menu can open 5px to far and creates a gap.

    $menu.show();
    // -1px to remove a line colored like the menu bg
    var $menuMarginTop = -1 - $menu.height();
    $('[data-nav-dropdown="menuMarginTop"]').css({
        'margin-top': $menuMarginTop
    });

    // menu height +5px to create a border at the bottom.
    var $menuHeight = ofsetBar + $menu.height();
    $menu.css({
        'height': $menuHeight
    });

    // menuTop is set in slideMenuDown() and endAtY to make it able to slide the menu open all the way
    var $menuTop = $menu.height(); - ofsetBar;

    // On window orientationchange change these variables
    $(window).resize(function() {
        // Remove the previous set inline-styles
        $menu.removeAttr('style');
        $('[data-nav-dropdown="menuMarginTop"]').removeAttr('style');
        $menu.show();
        // recalculate the variables
        // -1px to remove a line colored like the menu bg
        $menuMarginTop = -1 - $menu.height();
        $('[data-nav-dropdown="menuMarginTop"]').css({
            'margin-top': $menuMarginTop
        });

        // menu height +5px to create a border at the bottom.
        $menuHeight = ofsetBar + $menu.height();
        $menu.css({
            'height': $menuHeight
        });

        // menuTop is set in slideMenuDown() and endAtY to make it able to slide the menu open all the way
        $menuTop = $menu.height() - ofsetBar;
        removeCssClasses();
        slideMenuUp();
    });

    var $menuButton = $('[data-nav-dropdown="menu-btn"]');
    var $menuToggle = $('[data-nav-dropdown="menu-toggle"]');
    var $caret = $('[data-nav-dropdown="caret-icon"]');
    var $close = $('[data-nav-dropdown="text-is-close"]');
    var $menuText = $('[data-nav-dropdown="text-is-menu"]');
    var $document = $(document);
    var startAtY = 0; // CSS px
    var endAtY = $menuTop - ofsetBar;
    var clickEventType= document.ontouchstart !== null ? 'mousedown' : 'touchstart';
    var moveEventType = document.ontouchmove  !== null ? 'mousemove' : 'touchmove';
    var endEventType = document.ontouchend   !== null ? 'mouseup' : 'touchend';
    var clickedAtY;
    var menuTopStart;

    function addCssClasses() {
        $caret.addClass('rotate');
        $close.addClass('is-active');
        $menuText.addClass('is-active');
        $menuButton.addClass('is-active');
        $menuToggle.addClass('is-active');
        $menu.addClass('is-active');
    }

    function removeCssClasses() {
        $caret.removeClass('rotate');
        $close.removeClass('is-active');
        $menuText.removeClass('is-active');
        $menuButton.removeClass('is-active');
        $menuToggle.removeClass('is-active');
        $menu.removeClass('is-active');
    }

    function slideMenuDown() {
        $menu.animate({
            top: $menuTop - ofsetBar
        }, 'slow');
        $('[data-nav-dropdown="not-active"]').attr('data-nav-dropdown','active');
    }

    function slideMenuUp() {
        $menu.animate({
            top: '0px'
        }, 'slow');
        $('[data-nav-dropdown="active"]').attr('data-nav-dropdown','not-active');
    }

    // TOUCHSTART
    $menuButton.on(clickEventType, function( e ) {
        e.preventDefault();

        clickedAtY = e.pageY - $(this).offset().top;
        if(clickEventType === 'touchstart') {
            clickedAtY = e.originalEvent.touches[0].pageY - $(this).offset().top;
        }

        $document.on(moveEventType, moveHandler)
            .on(endEventType, stopHandler);

        menuTopStart = parseInt($menu.eq(0).css('top'));
    });

    // TOUCHMOVE
    function moveHandler( e ) {
        var posY = e.pageY - clickedAtY;
        if(moveEventType === 'touchmove') {
            posY = e.originalEvent.touches[0].pageY - clickedAtY;
        }
        posY = Math.min( Math.max(startAtY, posY), endAtY - startAtY);
        $menu.css({top: posY});

        var menuTopMove = parseInt($menu.eq(0).css('top'));
        if ( menuTopMove >= 1 ) {
            addCssClasses();
        }
    }

    // TOUCHEND
    function stopHandler() {
        $document.off(moveEventType, moveHandler)
            .off(endEventType,  stopHandler);

        var menuTopEnd = parseInt($menu.eq(0).css('top'));
        if ( menuTopEnd <= 1 ) {
            removeCssClasses();
        }

        var fakeSwipe = menuTopEnd - menuTopStart;
        var isSwipeDown = fakeSwipe >= 1 && fakeSwipe <= 75;
        var isSwipeUp = fakeSwipe >= -75 && fakeSwipe <= -1;
        var isClick = fakeSwipe === 0;

        if (isSwipeDown) {
            swipeDowny();
        }

        if (isSwipeUp) {
            swipeUppy();
        }

        if (isClick) {
            clickert();
        }

        function swipeDowny() {

            if ($('[data-nav-dropdown="not-active"]').length) {
                addCssClasses();
                slideMenuDown();
            }
        }

        function swipeUppy() {

            if ($('[data-nav-dropdown="active"]').length) {
                removeCssClasses();
                slideMenuUp();
            }
        }

        function clickert() {

            if ($('[data-nav-dropdown="not-active"]').length) {
                addCssClasses();
                slideMenuDown();
            } else {
                removeCssClasses();
                slideMenuUp();
            }
        }
    }
});
