(function() {
  var Popup;

  Popup = (function() {
    function getTemplate(key) {
        var deferred = new $.Deferred(),
            self     = this;

        if (this.popups[key].remoteTemplate === null) {
            setTimeout(function() {
                var $template = $(self.popups[key].templateSelector);

                if ($template.length === 0) {
                    return deferred.reject();
                }

                deferred.resolve($template.html());
            }, 1);
        } else {
            application.loadTemplate(this.popups[key].remoteTemplate).then(
                deferred.resolve,
                deferred.reject
            );
        }

        return deferred.promise();
    }

    function getContainerTemplate() {
        var deferred = new $.Deferred(),
            self     = this;

        if (this.options.remoteContainerTemplate === null) {
            setTimeout(function() {
                var $template = $(self.options.containerTemplateSelector);

                if ($template.length === 0) {
                    return deferred.reject();
                }

                deferred.resolve($template.html());
            }, 1);
        } else {
            application.loadTemplate(this.options.remoteContainerTemplate).then(
                deferred.resolve,
                deferred.reject
            );
        }

        return deferred.promise();
    }

    /**
     * Constructs a new Popup
     */
    function Popup(options)
    {
        this.defaults = {
            containerTemplateSelector : '#popup-container-template',
            remoteContainerTemplate   : '/html/tpl/popup/container.html',
            templateEngine            : Hogan,
            defaultPopupOptions       : {
                key              : 'identifier',
                templateSelector : '#some-popup-template',
                remoteTemplate   : null,
                templateEngine   : Hogan,
                closeListeners   : '.close, .overlay',
                popupClasses     : '',
                beforeOpen       : function() {},
                afterOpen        : function() {},
                beforeClose      : function() {},
                afterClose       : function() {},
                variables        : {
                    title            : 'Popup',
                    content          : 'test',
                    beforeOpen       : function() {},
                    afterOpen        : function() {},
                    beforeClose      : function() {},
                    afterClose       : function() {},
                    closeIconEnabled : true
                }
            }
        };

        // Keeps rendertime variables bases on the unique render id
        this.renderVariables = new Array();

        if (typeof options === 'undefined') {
            options = {};
        }

        // Set this options to defaults
        this.options = this.defaults;

        // Merge given options over all options
        this.options = $.extend(true, {}, this.options, options);

        // array of popup configs
        this.popups = [];

        this.remoteTemplateCache = {};
    }

    /**
     * Register a new popup
     *
     * @param string key
     * @param string templateSelector
     * @param object templateEngine
     */
    Popup.prototype.register = function(options)
    {
        if (typeof options.key === 'undefined') {
            throw 'Every popup must have its own unique key!';
        }
        this.popups[options.key] = $.extend(true, {}, this.options.defaultPopupOptions, options);
    }

    Popup.prototype.applyBindings = function(key, $container)
    {
        var self = this;
        $container.find(this.popups[key].closeListeners).on('click', function() {
            self.close(key, $container);
        });
    }

    Popup.prototype.close = function(key, $container, callback)
    {
        var self     = this,
            uniqueId = $container.attr('id');

        if (false === this.popups[key].beforeClose($container)) {
            return false;
        }
        if (false === this.renderVariables[uniqueId].beforeClose($container)) {
            return false;
        }

        $('body').removeClass('noscroll');

        $container.fadeOut(100, function() {
            $(window).unbind('keydown.' + key);

            self.popups[key].afterClose($container);
            self.renderVariables[uniqueId].afterClose($container);
            delete self.renderVariables[uniqueId];

            if ($.isFunction(callback)) {
                callback($container);
            }

            $container.remove();
        });
    }

    /**
     * Renders and shows a registered popup
     *
     * @param string key
     * @param object variables
     */
    Popup.prototype.render = function(key, variables)
    {
        if (typeof this.popups[key] === 'undefined') {
            throw 'No popup registered for key: ' + key + '!';
        }
        if (typeof variables === 'undefined') {
            variables = {};
        }

        variables = $.extend(true, {}, this.popups[key].variables, variables);
        var self              = this,
            uniqueId          = key + '_' + Math.round(Math.random() * 100000000),
            $container        = $('<div>').attr('id', uniqueId).hide().appendTo('body');

        $.when(
            getContainerTemplate.call(this, key),
            getTemplate.call(this, key)
        ).then(
            function(containerTemplate, popupTemplate) {
                var containerCompiled = self.options.templateEngine.compile(containerTemplate),
                    popupCompiled     = self.popups[key].templateEngine.compile(popupTemplate),
                    popupRendered     = popupCompiled.render(variables),
                    containerRendered = containerCompiled.render({
                         popup            : popupRendered,
                         popupVariables   : variables,
                         popupClasses     : self.popups[key].popupClasses,
                         closeIconEnabled : variables.closeIconEnabled
                     });

                self.renderVariables[uniqueId] = variables;

                $(containerRendered).appendTo($container);

                self.applyBindings(key, $container);

                if (false === self.popups[key].beforeOpen($container)) {
                    $container.remove();
                    return;
                }

                if (false === variables.beforeOpen($container)) {
                    $container.remove();
                    return;
                }

                $('body').addClass('noscroll');

                $container.fadeIn(100, function() {
                    $(window).on('keydown.' + key, function(e) {
                        if (e.which === 27) {
                            self.close.call(self, key, $container);
                        }
                    });

                    // Call the afteropen callback
                    self.popups[key].afterOpen($container);
                    variables.afterOpen($container);
                });
            }
        );

        return $container;
    }

    return Popup;

  })();

  this.Popup = Popup;
}).call(this);
