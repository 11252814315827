(function() {
  var Search;

  Search = (function()
  {
    /**
     * Constructs a new Search
     */
    function Search(application)
    {
        this.application = application;
    }

    Search.prototype.cities = function(countryCode, region)
    {
        var deferred = new $.Deferred();
        $.when(
            this.application.callAjax(
                'common',
                'getCitiesByRegion',
                {
                    countryCode  :  countryCode,
                    region : region
                }
            )
        ).then(
            function(results) {
                deferred.resolve(results);
            },
            function(jqXHR, textStatus, errorThrown) {
                deferred.reject(textStatus);
            }
        );
        return deferred.promise();
    }

      Search.prototype.getRegions = function(activeFilter)
      {
          var deferred      = new $.Deferred(),
              optionsFilter = $.extend(true, {
              strategy: window.application.options.geoStrategy,
              level: 1
          }, (typeof activeFilter !== 'undefined' ? activeFilter : {}));

          $.when(
              this.application.callApi(
                  'geoapi',
                  'places',
                  'options',
                  optionsFilter,
                  'GET'
              )
          ).then(
              function(results) {
                  deferred.resolve(results);
              },
              function(jqXHR, textStatus, errorThrown) {
                  deferred.reject(textStatus);
              }
          )
          return deferred.promise();
      }

    Search.prototype.getByClientId = function(clientid, includePhotos)
    {
        var deferred = new $.Deferred();

        $.when(
            this.search(
                {eq: {clientid: clientid}},
                [],
                false,
                1,
                0,
                '',
                true,
                includePhotos
            )
        ).then(
            function(results) {
                if (typeof results[0] !== 'undefined') {
                    deferred.resolve(results[0]);
                }
                deferred.reject('not_found');
            },
            function(textStatus) {
                deferred.reject(textStatus);
            }
        );

        return deferred.promise();
    }

    /**
     * Creates an initial empty profile in the system
     *
     * @returns deferred object
     */
    Search.prototype.search = function(
        conditions,
        orderby,
        online,
        limit,
        offset,
        eventPrefix,
        suppressEvents,
        includePhotos,
        filter
    ) {
        var deferred = new $.Deferred(),
            args     = {
                conditions : conditions,
                orderby    : orderby,
                online     : online,
                limit      : limit,
                offset     : offset
            };
        if (typeof eventPrefix === 'undefined') {
            eventPrefix = 'search_';
        }
        if (typeof suppressEvents === 'undefined' || true !== suppressEvents) {
            suppressEvents = false;
        }
        if (typeof includePhotos === 'undefined' || true !== includePhotos) {
            includePhotos = false;
        }

        filter = filter || '';

        if (false === suppressEvents) {
            $(this).trigger(
                eventPrefix + 'search',
                {
                    args   : args
                }
            );
        }

        $.when(
            this.application.callAjax(
                'profile',
                'search',
                {
                    conditions    : conditions,
                    orderby       : orderby,
                    online        : online,
                    limit         : limit,
                    offset        : offset,
                    includePhotos : includePhotos,
                    filter        : filter
                },
                true
            ),
            this,
            args
        ).then(
            function(results, search, args) {
                for (var i in results) {
                    if (typeof results[i]['gender'] !== 'undefined') {
                        results[i]['gender'] = {
                            male        : results[i]['gender'] === 'male',
                            female      : results[i]['gender'] === 'female'
                        };
                    }
                }
                deferred.resolve(results);
                if (false === suppressEvents) {
                    $(search).trigger(
                        eventPrefix + 'results',
                        {
                            results: results,
                            args   : args
                        }
                    );
                }
            },
            function(jqXHR, textStatus, errorThrown) {
                deferred.reject(textStatus);
            }
        );
        return deferred.promise();
    };

    /**
     * Count profiles based on conditions and the online flag
     *
     * @param array|object conditions
     * @param boolean      online
     * @param integer      limit       // triggered event info
     * @param integer      offset      // triggered event info
     * @param string       eventPrefix
     * @param boolean      suppressEvents
     *
     * @triggers %eventprefix%count {args: {}}                 // before counting
     * @triggers %eventprefix%count {count: %count%, args: {}} // on count result
     *
     * @returns deferred integer
     */
    Search.prototype.count = function(
        conditions,
        online,
        limit,
        offset,
        eventPrefix,
        suppressEvents,
        filter
    ) {
        var deferred = new $.Deferred(),
            args     = {
                conditions : conditions,
                online     : online,
                limit      : limit,
                offset     : offset
            };
        if (typeof eventPrefix === 'undefined') {
            eventPrefix = 'search_';
        }
        if (typeof suppressEvents === 'undefined' || true !== suppressEvents) {
            suppressEvents = false;
        }

        filter = filter || '';

        if (false === suppressEvents) {
            $(this).trigger(
                eventPrefix + 'count',
                {
                    args   : args
                }
            );
        }

        $.when(
            this.application.callAjax(
                'profile',
                'count',
                {
                    conditions : conditions,
                    online     : online,
                    filter     : filter
                },
                true
            ),
            this,
            args
        ).then(
            function(count, search, args) {
                deferred.resolve(count);
                if (false === suppressEvents) {
                    $(search).trigger(
                        eventPrefix + 'count',
                        {
                            count: count,
                            args : args
                        }
                    );
                }
            },
            function(jqXHR, textStatus, errorThrown) {
                deferred.reject(textStatus);
            }
        );
        return deferred.promise();
    };

    /**
     * Get a list of online profiles mixed by their gender
     *
     * @param integer limit
     * @param integer limit
     * @returns deferred profiles
     */
    Search.prototype.mixedOnline = function(limit)
    {
        var deferred = new $.Deferred();

        if (typeof limit === 'undefined') {
            limit = 30;
        }

        $.when(
            this.search(
                {
                    'in' : {
                        gender : ['male', 'female']
                    },
                    split : {
                        type : 'equal',
                        by   : 'gender'
                    }
                },
                {
                    lastActivity :'desc'
                },
                true,
                limit,
                0,
                'search_',
                true,
                false,
                'online'
            )
        ).then(
            function(online) {
                var onlineWomen         = online['female'],
                    onlineMen           = online['male'];

                var mixedOnline = [];
                for (var i = 0; i < limit; i++) {
                    if ((0 === onlineWomen.length && 0 === onlineMen.length) || limit === mixedOnline.length) {
                        break;
                    }
                    if (onlineWomen.length > 0) {
                        mixedOnline.push(onlineWomen.shift());
                    }
                    if (onlineMen.length > 0) {
                        mixedOnline.push(onlineMen.shift());
                    }
                }
                deferred.resolve(mixedOnline);
            },
            function(textStatus) {
                deferred.reject(textStatus);
            }
        );
        return deferred.promise();
    }

    /**
     * Throws an error prefixed with the Search name
     *
     * @param string error
     */
    Search.prototype.throwError = function(error) {
        throw 'Search ERROR: ' + error;
    }

    return Search;

  })();

  this.Search = Search;
}).call(this);
