(function() {
  var Message;

  function enableSendBtn() {
    $('[data-conversation="send"]').removeClass('is-loading').removeAttr('disabled');
  }

  function disableSendBtn() {
    $('[data-conversation="send"]').addClass('is-loading').attr('disabled', 'disabled');
  }

  Message = (function()
  {
    /**
     * Constructs a new Message
     */
    function Message(application, options)
    {
        this.defaults = {
            messageFormSelector       : '#messageForm',
            submitButtonSelector      : '.submitform',
            errorMessageSelector      : '.error-message',
            attachmentElementId       : '#attachment',
            onSuccess                 : function() {},
            onError                   : function() {},
            onUnsufficientCredits     : function() {}
        };
        if (typeof options === 'undefined') {
            options = {};
        }

        // Set this options to defaults
        this.options = this.defaults;

        // Merge given options over all options
        this.options = $.extend(true, {}, this.options, options);

        // Load dependecies
        yepnope({
            test : window.storage,
            nope : '/js/storage.js'
        });

        this.bindSubmitButtons();

        this.bindForm();
    }

    Message.prototype.bindSubmitButtons = function()
    {
        var self = this;
        $(this.options.messageFormSelector + ' ' + this.options.submitButtonSelector).on('click', function() {
            var $this = $(this);
            if ($this.attr('disabled') === 'disabled') {
                return false;
            }

            disableSendBtn();
            $this.closest(self.options.messageFormSelector).find('input[name="device"]').val($this.attr('rel'));
            $this.closest(self.options.messageFormSelector).submit();
            return false;
        });
    }

    Message.prototype.bindForm = function()
    {
        var self = this;
        $(this.options.messageFormSelector).on('submit', function(e) {
            var $form    = $(this),
                formData = $form.serializeObject();

            e.preventDefault();

            $.when(self.uploadAttachment.call(self)).then(
                function(attachment) {
                    self.send.call(
                        self,
                        formData.msgid,
                        formData.to,
                        formData.subject,
                        formData.message,
                        attachment,
                        $form
                    );
                },
                function() {
                    alert('error!');
                }
            );
            return false;
        });
    }

    Message.prototype.uploadAttachment = function()
    {
        var deferred = new $.Deferred(),
            self     = this;

        if ($(this.options.attachmentElementId).length !== 1 || $(this.options.attachmentElementId).val() === '') {
            deferred.resolve(null);
            return deferred.promise();
        }

        $.when(
            window.application.callAjaxUpload('message', 'attachment', this.options.attachmentElementId)
        ).then(
            function(data) {
                deferred.resolve(data.data.name);
            },
            function(data) {
                var errors        = data.errors,
                    errorMessages = window.application.options.uploadErrors;

                enableSendBtn();

                if ($.isFunction(self.options.onError)) {
                    self.options.onError(self, errors[0].code, errors[0].desc);
                }
                if ($(self.options.messageFormSelector).length > 0) {
                    $(self.options.messageFormSelector).find(self.options.errorMessageSelector).html(errorMessages[errors[0].code]).show();
                }
            }
        );

        return deferred.promise();
    }

    /**
     *
     * @param integer msgid**
     * @param string  to
     * @param string  subject
     * @param string  message
     * @param string  attachment The !!already uploaded!! attachment
     *
     * ** Not required, provice null for new messages
     *
     * @returns {undefined}
     */
    Message.prototype.send = function(msgid, to, subject, message, attachment, $form)
    {
        var self     = this,
            postData = {};

        if (typeof msgid !== 'undefined' && msgid != '') {
            postData['msgid'] = msgid;
        }
        if (typeof to !== 'undefined' && to != '') {
            postData['to'] = to;
        }
        if (typeof subject !== 'undefined') {
            postData['subject'] = subject;
        }
        if (typeof message !== 'undefined' && message != '') {
            postData['message'] = message;
        }
        if (typeof attachment !== 'undefined') {
            postData['attachment'] = attachment;
        }

        $(self.options.messageFormSelector).find(self.options.errorMessageSelector).hide();

        $.when(
            window.application.callAjax(
                'message',
                'send',
                postData
            )
        ).then(
            function(data) {
                var $creditsCounter = $('[data-counter="credits.left"]:visible').first(),
                    creditsLeft     = parseInt($creditsCounter.text());

                data.subject = postData.subject;

                if ($(self.options.messageFormSelector).length > 0) {
                    $(self.options.messageFormSelector)[0].reset();
                }

                $(self.options.messageFormSelector + ' ' + self.options.submitButtonSelector).removeClass('disabled');

                if (creditsLeft > 0) {
                    $('[data-counter="credits.left"]').text(creditsLeft - 1);
                }

                $('[data-message="success"]').show();

                $('[data-icon="attachment-icon"]')
                    .addClass('fa-camera attachment-not-added')
                    .removeClass('fa-paperclip attachment-added');
                enableSendBtn();

                self.options.onSuccess(self, data);
            },
            function(errors, data) {
                $(self.options.messageFormSelector + ' ' + self.options.submitButtonSelector).removeClass('disabled');
                enableSendBtn();

                if (errors[0].code === 'insufficient_credits') {
                    if ($.isFunction(self.options.onUnsufficientCredits)) {
                        self.options.onUnsufficientCredits(self, data, postData, $form);
                    }
                } else {
                    if ($.isFunction(self.options.onError)) {
                        self.options.onError(self, errors[0].code, errors[0].desc);
                    } else {
                        $(self.options.messageFormSelector).find(self.options.errorMessageSelector).html(errors[0].desc).show();
                    }
                    $("html, body").animate({
                        scrollTop: 0
                    }, 600)
                }
            }
        );
    }

    return Message;

  })();

  this.Message = Message;
}).call(this);
