(function($, window) {
    var dataNamespace = 'app.login',
        old   = $.fn.login,
        Login = function (container, options) {
            var self = this;

            this.cnt      = $(container);
            this.options  = options;

            init.call(this);
        };

    Login.defaults = {};

    function init() {
        var self = this,
            $loginCnt  = $('[data-cnt="login"]', this.cnt),
            $forgotCnt = $('[data-cnt="forgot"]', this.cnt);
			
		

        $('[data-toggle="container"]', this.cnt).click(function(e) {
            e.preventDefault();

            self.switchContainer.call(self, $(this).data('to'));
        });
		
        $('[data-toggle="login"]', $loginCnt).click(function(e) {
            e.preventDefault();
            e.stopPropagation();

            var $this = $(this);

            if (application.control.button.lock($this) === false) {
                return;
            }

            self.login.call(
                self,
                $('[data-field="username"]', $loginCnt).val(),
                $('[data-field="password"]', $loginCnt).val()
            ).then(
                function() {
                    application.control.button.unlock($this);
                },
                function() {
                    application.control.button.unlock($this);
                }
            );
        });

        $('form', $loginCnt).submit(function(e) {
            e.preventDefault();

            $('[data-toggle="login"]', self.cnt).click();
        });

        $('[data-toggle="forgot"]', $forgotCnt).click(function(e) {
			e.preventDefault();
            e.stopPropagation();

            var $this = $(this);

            if (application.control.button.lock($this) === false) {
                return;
            }

            self.forgot.call(self, $('[data-field="email"]', $forgotCnt).val()).then(
                function() {
                    application.control.button.unlock($this);
                },
                function() {
                    application.control.button.unlock($this);
                }
            );
        });

        $('form', $forgotCnt).submit(function(e) {
            e.preventDefault();
            e.stopPropagation();

            $('[data-toggle="forgot"]', $forgotCnt).click();
        });
    }

    Login.prototype.switchContainer = function(container)
    {
        $('[data-cnt]', this.cnt).hide();
        $('[data-cnt="' + container + '"]', this.cnt).show();
    };

    Login.prototype.login = function(username, password)
    {
        var $loginCnt  = $('[data-cnt="login"]', this.cnt),
            $errorCnt  = $('.error-container', $loginCnt),
            $toggleCnt = $('.container-toggle', $loginCnt),
            deferred   = new $.Deferred;

        window.application.callAjax(
            'auth',
            'logon',
            {
                email    : username,
                password : password
            }
        ).then(
            function() {
                deferred.resolve();

                window.location.href = window.application.options.logonRedirect;
            },
            function() {
                deferred.reject();

                $errorCnt.show();
                $toggleCnt.hide();

                setTimeout(function() {
                    $errorCnt.hide();
                    $toggleCnt.show();
                }, 2000);
            }
        );

        return deferred.promise();
    };

    Login.prototype.forgot = function(email)
    {
        var $forgotCnt = $('[data-cnt="forgot"]'),
            $errorCnt  = $('.error-container', $forgotCnt),
            deferred   = new $.Deferred;

        window.application.callAjax(
            'auth',
            'requestPassword',
            {
                email : email
            }
        ).then(
            function(result) {
                deferred.resolve();
                $('form', $forgotCnt).html("<h4>"+result+"</h4>");
				$('.forgottitle', $forgotCnt).hide();
            },
            function(errors) {
                deferred.reject();

                for (var i = 0; i < errors.length; i++) {
                    $errorCnt.html(errors[i].desc).show();

                    setTimeout(function() {
                        $errorCnt.html('').hide();
                    }, 3000);

                    break;
                }
            }
        );

        return deferred.promise();
    };

    // Register plugin
    $.fn.login = function (option) {
        var callArgs = arguments;

        result = this.each(function() {
            var $this   = $(this),
                data    = null,
                options = null,
                args    = [];

            data    = $this.data(dataNamespace);
            options = $.extend(true, {}, Login.defaults, $this.data(), option);

            if (data === undefined) {
                $this.data(dataNamespace, (data = new Login(this, options)));
            }

            if (typeof option === 'string') {
                if (data[option] === undefined) {
                    throw 'Method ' + option + ' does not exists';
                }

                Array.prototype.push.apply(args, callArgs);
                args.shift();

                // call methods
                return data[option].apply(data, args);
            }
        });

        return result;
    };

    $.fn.login.Constructor = Login;

    // No conflict
    $.fn.login.noConflict = function () {
        $.fn.login = old;
        return this;
    };

}(jQuery, window));
