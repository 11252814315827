$(function() {
    if (window.location.pathname !== '/members') {
        return false;
    }

    var $menu = $('[data-nav-dropdown="menu-bottom"]');
    var offsetBar = 5; // 5 is to account for an ofset which creates a 5px bar effect. If not there, the menu opens not far enough and will not go totaly over the bottom sticky box.

    $menu.show();

    // Caculate the position of the whole menu at page load
    var $menuWrapperBottom = $('[data-nav-dropdown="menu-wrapper"]');
    var menuWrapperBottomValue;
    var menuPosition;

    // menuPosition is the viewportheigt turned into a nagative + 2 times the css bottom value of
    // menu-wrapper (which is a negative). To turn that negative into a positive this is added: - ($menuWrapperBottomValue * 4)
    // We need this value to know where the menu starts and the bottom value, if there is one, is needed for it will make
    // the menu jump up or down on touch of the button. This calculation prevents that.
    function calculateMenuPosition() {
        menuWrapperBottomValue = parseInt($menuWrapperBottom.eq(0).css('bottom'));
        menuPosition = -($( window ).height()) + menuWrapperBottomValue * 2 - (menuWrapperBottomValue * 4);
    }

    // call the function to calculate the values after page is loaded
    calculateMenuPosition();

    // END calculate the position of the whole menu at page load

    var $menuMarginBottom = offsetBar + $menu.height();
    $('[data-nav-dropdown="menuMarginTopAtBottom"]').css({
        'margin-bottom': $menuMarginBottom
    });

    // menu height +5px to create a border at the bottom.
    var $menuHeight = offsetBar + $menu.height();
    $menu.css({
        'height': $menuHeight
    });

    var stickBottomMenuHeight = $('.mobile-bottom-box-menu').height();
    // menuTop is set in slideMenuDown() and endAtY to make it able to slide the menu open all the way over the bottom sticky menu
    var $menuTop = -($menu.height()) - stickBottomMenuHeight - offsetBar;


    var $menuButton = $('[data-nav-dropdown="menu-bottom-btn"]'),

        $document = $(document),
        startAtY = menuPosition,
        endAtY = $menuTop + offsetBar,
        clickEventAtBottomType= document.ontouchstart !== null ? 'mousedown' : 'touchstart',
        endEventAtBottomType  = document.ontouchend   !== null ? 'mouseup'   : 'touchend'  ;

    function slideMenuDown() {
        $menu.animate({
            top: -startAtY
        }, 500);
        $('[data-nav-dropdown-bottom="active"]').attr('data-nav-dropdown-bottom','not-active');
    }

    function slideMenuUp() {
        $menu.animate({
            top: endAtY
        }, 'slow');
        $('[data-nav-dropdown-bottom="not-active"]').attr('data-nav-dropdown-bottom','active');
    }

    // TOUCHSTART
    $menuButton.on(clickEventAtBottomType, function(e) {
        e.preventDefault();

        $document.on(endEventAtBottomType, stopHandler);
    });

    // TOUCHEND
    function stopHandler() {
        $document.off(endEventAtBottomType,  stopHandler);

        function clickert() {
            if ($('[data-nav-dropdown-bottom="active"]').length) {
                slideMenuDown();
            } else {
                slideMenuUp();
            }
        }

        var menuTopEnd = parseInt($menu.eq(0).css('top'));

        if ( menuTopEnd === 0 ) {
            $('[data-nav-dropdown-bottom="active"]').attr('data-nav-dropdown-bottom','not-active');
        }

        clickert();

        calculateMenuPosition();
    }

    $('[data-form="search-bar"]').on('change', function() {
        slideMenuDown();

        $('html, body').animate({ scrollTop: 0 });
    });
});
