/**
 * geoDataService.js
 * For info go to: https://project89109.atlassian.net/wiki/pages/viewpage.action?pageId=43641273
 */

/**
 *
 * @type {{getInstance}}
 */
var geoDataService = (function () {

    // Instance stores a reference to the Singleton
    var instances = [];

    function init() {

        "use strict";

        // Private variables and functions
        var geoStrategy = window.application.options.geoStrategy;
        var geoLanguage = window.application.options.geoLanguage;
        var regions;
        var cities;
        var initialValues;
        var usedStructure;

        var geoPostcodeStrategy = window.application.options.geoPostcodeStrategy;
        var geoIso      = window.application.options.iso;

        //variable for autocitysuggest
        var selectedRegionHolder;

        //get the region and city for the platform and slice the 'geo' part away
        var cityDisplayValue    = window.application.options.customSettings.geo.displayCity.slice(3).toLowerCase();
        var regionDisplayValue = window.application.options.customSettings.geo.displayRegion.slice(3).toLowerCase();
        // Object to hold all the jquery dropdown selectors
        var geoFields = {};

        /**
         *  Returns an array of regions objects based on the specified geoStrategy in the _custom.xml
         * @returns {*}
         */
        var getRegions = function (initialFilter) {
            if (typeof initialFilter === 'undefined') {
                initialFilter = {};
            }
            var filter = $.extend({}, initialFilter, {
                strategy: geoStrategy,
                level: 1,
                language: geoLanguage
            });
            return window.application.callApi(
                'geoapi',
                'places',
                'options',
                filter,
                'GET'
            ).then(
                function (result) {
                    return result.data;
                });
        };

        /**
         *  Returns an array of cities based on the region
         * @param selectedRegion
         * @returns {*}
         */
        var getCities = function (selectedRegion) {

            return window.application.callApi(
                'geoapi',
                'places',
                'options',
                {
                    strategy: geoStrategy,
                    language: geoLanguage,
                    level: 2,
                    iso: selectedRegion.iso,
                    region1: selectedRegion.region1
                },
                'GET'
            ).then(
                function (result) {
                    return result.data;
                });
        };

        /**
         * Returns an array of auto suggested cities based on the input value
         * @param city
         * @returns {*}
         */
        var autoSuggestCities = function (city) {

            return window.application.callApi(
                'geoapi',
                'places',
                'suggest',
                {
                    strategy: geoStrategy,
                    language: geoLanguage,
                    search: city,
                    searchTarget: 'locality'
                },
                'GET'
            ).then(
                function (result) {
                    return result.data;
                });
        };

        var findCitybyPostcode = function (postcode) {

            return window.application.callApi(
                'geoapi',
                'places',
                'options',
                {
                    strategy: geoPostcodeStrategy || geoStrategy,
                    language: geoLanguage,
                    level: 2,
                    postcode: postcode,
                    iso: geoIso
                },
                'GET'
            ).then(
                function (result) {
                    return result.data;
                });
        };


        /**
         *  Assign the correct html fields bases on the structure
         * @param fields
         * @param structure
         * @returns {{}}
         */
        var bindGeoFields = function (fields, structure) {
            var self = {};

            if (structure === "dropdowns") {
                self.regionDropdown = fields.region;
                self.cityDropdown = fields.city;
            } else if (structure === "autoSuggestCity") {
                if (fields.region) {
                    self.regionInput = fields.region;
                }
                self.cityInput = fields.city;
            } else if (structure === "postcode") {
                self.postcodeInput = fields.postcode;
                self.cityDropdown     = fields.city;
                self.cityInput = fields.city;
            }

            return self;
        };

        /**
         * Stores regions in the session storage
         * @param regions
         */
        var storeRegions = function (regions) {
            window.sessionStorage.setItem('platform__region-results', JSON.stringify(regions));
        };

        /**
         * Add loading state to specified dropdown
         * @param dropdown
         */
        var toggleLoading = function (dropdown) {
            dropdown.parent().toggleClass('is-loading');
        };


        var fillMatchRegions = function ($regionDropdown, $matchGender, $minAge, $maxAge) {

            window.application.callAjax('account', 'getSearch').then(
                function (response) {
                    $matchGender.val(response.gender);
                    //$regionDropdown.val(response.geoRegion2);
                    $minAge.val(response.minAge);
                    $maxAge.val(response.maxAge);
                    var initialFilter = {};
                    if (response.geoRegion1 !== 'all') {
                        initialFilter["region1"] = response.geoRegion1;
                    }
                    if (response.geoRegion2 !== 'all') {
                        initialFilter["region2"] = response.geoRegion2;
                    }
                    if (response.geoRegion3 !== 'all') {
                        initialFilter["region3"] = response.geoRegion3;
                    }
                    if (response.geoRegion4 !== 'all') {
                        initialFilter["region4"] = response.geoRegion4;
                    }

                    getRegions(initialFilter).then(function (regions) {
                        regions.forEach(function (region, i) {
                            var option = new Option(region.label);
                            if (region.isActive) {
                                option.setAttribute('selected', 'selected');
                            }
                            if (typeof region.filter !== 'undefined') {
                                $(option).data('region-filter', region.filter);
                            }
                            if (typeof region.place !== 'undefined') {
                                $(option).data('region-place', region.place);
                            }
                            $regionDropdown.append($(option));
                        });

                    });
                }
            );
        }

        var fillRegionDropdown = function () {
            toggleLoading(geoFields.regionDropdown);

            getRegions().then(function (regionsArr) {
                var triggerChange = false;
                regions = regionsArr;
                storeRegions(regions);
                toggleLoading(geoFields.regionDropdown);

                regions.forEach(function (region, i) {
                    var regionValue = region.filter[regionDisplayValue];
                    var option = new Option(region.label);
                    if (region.isActive) {
                        option.setAttribute('selected', 'selected');
                        triggerChange = true;
                    }
                    if (Object.keys(initialValues).length !== 0 && region.label === initialValues.region) {
                        option.setAttribute('selected', 'selected');
                    }
                    if (typeof region.filter !== 'undefined') {
                        $(option).data('region-filter', region.filter);
                    }
                    if (typeof region.place !== 'undefined') {
                        $(option).data('region-place', region.place);
                    }
                    geoFields.regionDropdown.append($(option));
                });
                if (triggerChange) {
                    geoFields.regionDropdown.trigger("change");
                }
            });
        };

        var bindRegionDropdownEvents = function () {
            geoFields.regionDropdown.on('change', fillCityDropdown);
        };

        var fillCityDropdown = function () {

            var selectedRegion = getSelectedRegion();
            geoFields.cityDropdown.find("option:not(:first)").remove();

            if (!selectedRegion) {
                return;
            }

            toggleLoading(geoFields.cityDropdown);

            getCities(selectedRegion).then(function (citiesArr) {

                cities = citiesArr;
                toggleLoading(geoFields.cityDropdown);

                cities.forEach(function (city, i) {

                    var cityLabel = city.label;
                    var cityValue = city.place.locality;
                    var option = new Option(cityValue, cityLabel);

                    if (Object.keys(initialValues).length !== 0 && cityValue === initialValues.city) {
                        option.setAttribute('selected', 'selected');
                    }

                    option.setAttribute('data-city', i);
                    geoFields.cityDropdown.append($(option));
                });

            });
        };

        var getSelectedRegion = function () {

            if (geoFields.regionDropdown.val() === "all") {
                return false;
            }

            var filter   = geoFields.regionDropdown.find(':selected').data('region-filter');

            if (typeof filter === 'undefined') {
                return false;
            }

            return filter;

        };

        /**
         *  Returns a place object based on the value of data-field='city'
         * @returns {*}
         */
        var getSelectedPlaceObject = function () {

            var selectedCity = geoFields.cityInput.val();
            var selectedPlaceObject = null;

            if (cities && typeof cities !== 'undefined') {

                cities.forEach(function (city) {
                    if (usedStructure === 'autoSuggestCity') {
                        if (city.place[cityDisplayValue] === selectedCity && city.place[regionDisplayValue] === selectedRegionHolder) {
                            selectedPlaceObject = city.place;
                        }
                    } else {
                        if (city.place[cityDisplayValue] === selectedCity) {
                            selectedPlaceObject = city.place;
                        }
                    }
                });
            } else {
                window.console.info("Cities are not loaded.");
            }

            return selectedPlaceObject;

        };

        /**
         * Appends the city input with auto suggested cities,
         * based on the (city) value the user provides.
         * @param city
         */
        var fillCityInput = function (city) {

            autoSuggestCities(city).then(
                function (citiesArr) {

                    cities = citiesArr;

                    var resultContainer = $("[data-result='searchCity']");
                    var container = $("[data-cnt='searchCity']");
                    var listItem;

                    resultContainer.html('');
                    container.show();

                    if (!Array.isArray(cities) || !cities.length) {
                        listItem = $('<li>').append('<p>'+ window.application.options.geoNoCityInformation + '</p>');
                        resultContainer.append(listItem);
                        return;
                    }

                    cities.forEach(function (city, i) {
                        // If GEO region is undefined, find next in line
                        var place;
                        if(typeof city.place[regionDisplayValue] === 'undefined') {
                            var regionId = parseInt(regionDisplayValue.substr(-1));
                            for(; regionId > 0; --regionId){
                                if(typeof city.place['region' + regionId] !== 'undefined'){
                                    place = city.place['region' + regionId];
                                    break
                                }
                            }

                            // If still not found, don't show.
                            place = place !== undefined ? ' - ( ' + place + ' )' : ''
                        } else {
                            place = ' - ( ' + city.place[regionDisplayValue] + ' )'
                        }


                        listItem = $('<li>')
                            .attr('data-region1', city.place.region1)
                            .attr('data-region2', city.place.region2)
                            .attr('data-region3', city.place.region3)
                            .attr('data-region4', city.place.region4)
                            .attr('data-locality', city.place.locality)
                            .attr('city-id', i)
                            .append("<p>" + city.place[cityDisplayValue] + "<span class='region'>" + place + "</span></p>");
                        resultContainer.append(listItem);

                        listItem.on('click', function (e) {
                            e.stopPropagation();
                            if (geoFields.regionInput) {
                                geoFields.regionInput.val($(this).data(regionDisplayValue));
                            }
                            geoFields.cityInput
                                .val($(this).data(cityDisplayValue))
                                .attr('data-region1', $(this).data('region1'))
                                .attr('data-region2', $(this).data('region2'))
                                .attr('data-region3', $(this).data('region3'))
                                .attr('data-region4', $(this).data('region4'))
                                .attr('data-locality', $(this).data('locality'));
                            //update this variable to compare later in selectedplaceobject
                            selectedRegionHolder = $(this).data(regionDisplayValue);
                            $('[data-field="region"]').val($(this).data(regionDisplayValue));
                            $('[data-register="confirm"]').removeClass('disabled');
                            container.hide();
                        });
                        // geoFields.cityDropdown.val(initialValues.city);
                    })
                },
                function (errors) {
                    window.console.log(errors);
                }
            );
        };

        var fillCityByPostcode = function (postcode) {
            findCitybyPostcode(postcode).then(
                function (citiesArr) {
                    cities = citiesArr;
                    geoFields.cityDropdown.empty();

                    if (citiesArr.length === 0) {
                        geoFields.cityDropdown.append('<option value="" class="error" disabled selected> No cities found</option>')
                    } else {
                        citiesArr.forEach(function (result, i) {

                            var cityLabel = result.label;
                            var cityValue = result.place.locality;

                            var option = new Option(cityValue, cityLabel);

                            option.setAttribute('data-city', i);
                            geoFields.cityDropdown.append($(option));

                            if (Object.keys(initialValues).length !== 0 && cityValue === initialValues.city) {
                                option.setAttribute('selected', 'selected');
                            }

                            // geoFields.cityDropdown.append('<option data-city="' + i + '" value="' + result.place.locality + '">' + result.label + '</option>');
                        })
                    }
                }
            )
        };

        var bindPostcodeEvents = function () {
            geoFields.postcodeInput.on('keyup', function () {

                var postcode = $(this).val();


                if (postcode.length < 4) {
                    return;
                }

                fillCityByPostcode(postcode);
            });
        };

        var fillCityInputTimeoutID = null;
        var bindCityInputEvents    = function () {

            geoFields.cityInput.on('keyup', function () {
                var city = $(this).val();

                if (city.length < 3) {
                    return;
                }

                if (null !== fillCityInputTimeoutID) {
                    clearTimeout(fillCityInputTimeoutID);
                }

                fillCityInputTimeoutID = setTimeout(function() {
                    fillCityInput(city);
                }, 500);
            });
        };

        /**
         * for the my profile page, fill the psotcode field on load.
         *
         */
        function fillInitialPostcode() {
            geoFields.postcodeInput.val(initialValues.postcode);
            geoFields.postcodeInput.trigger('keyup')
        }

        /**
         * Initialize geoData in form fields based on config.
         * @param config
         */
        var initGeo = function (config) {

            var structure = config.structure;
            usedStructure = config.structure
            var fields = config.fields;
            var disableCityField = config.disableCityField;

            if (config.initialValues) {
                initialValues = config.initialValues;
            } else {
                initialValues = {};
            }

            geoFields = bindGeoFields(fields, structure);

            if (structure === "dropdowns") {
                fillRegionDropdown();
                (disableCityField) ? '' : bindRegionDropdownEvents();
            } else if (structure === "autoSuggestCity") {
                bindCityInputEvents();
            } else if (structure === "postcode") {
                bindPostcodeEvents();
                fillInitialPostcode();
            }
        };

        // Public API
        return {
            initGeo: initGeo,
            getSelectedRegion: getSelectedRegion,
            getSelectedPlaceObject: getSelectedPlaceObject,
            getRegions: getRegions,
            fillMatchRegions: fillMatchRegions
        };

    }

    return {

        getInstance: function (instanceKey) {
            if (typeof instanceKey === 'undefined') {
                instanceKey = 'default';
            }
            if (typeof instances[instanceKey] === 'undefined') {
                instances[instanceKey] = init();
            }

            return instances[instanceKey];
        }

    };

})();

