(function() {
  var Application;

  Application = (function() {

    /**
     * Constructs a new Application
     */
    function Application(options) {
        var self      = this,
            latestXhr = null;

        this.storedParsers = {};

        this.defaults = {
            ajaxUrl            : null,
            enableCountRefresh : true,
            countRefreshRate   : 30000,
            device             : 'desktop'
        };

        if (typeof options === 'undefined') {
            options = {};
        }

        // Merge given options over all options
        this.options = $.extend(true, {}, this.defaults, this.options, options);

        if (null === this.options.ajaxUrl) {
            return this.throwError(
                'Ajax URL has not been configured!'
            );
        }

        this.environment = {
            isSplashPage: function() {
                return window.location.pathname === '/splash';
            },
            isMobile : function() {
                return self.options.device === 'mobile';
            },
            isTablet : function() {
                return self.options.device === 'tablet';
            },
            isDesktop : function() {
                return self.options.device === 'desktop';
            },
            isIE : function() {
                return (application.environment.getIEVersion() !== false);
            },
            getIEVersion : function() {
                var agent = navigator.userAgent.toLowerCase();

                return (agent.indexOf('msie') != -1) ? parseInt(agent.split('msie')[1]) : false;
            }
        };

        this.control = {
            button : {
                isLocked : function($element) {
                    return $element.hasClass('disabled') || ($element.data('disabled') === true);
                },
                lock : function($element) {
                    if (application.control.button.isLocked($element) === true) {
                        return false;
                    }

                    $element.addClass('disabled').data('disabled', true);
                    return true;
                },
                unlock : function($element) {
                    if (application.control.button.isLocked($element) === false) {
                        return false;
                    }

                    $element.removeClass('disabled').data('disabled', false);
                    return true;
                }
            }
        };

        this.service = {
            /**
             * Sets the text size as small as necessary to fit in the element
             * Element has to have a fixed height
             * Element has to have an attribute called `auto-decrease-font-size`
             * Working example:
             * <div auto-decrease-font-size style="height: 50px;">Lorem ipsum dolor, sit amet</div>
             */
            autoDecreaseFontSize: function() {
                var elems = document.querySelectorAll('[auto-decrease-font-size]');

                if (!elems.length) {
                    return false;
                }

                Array.from(elems).forEach(function(elem) {
                    var isElemOverflow = elem.scrollHeight > elem.offsetHeight;

                    while (isElemOverflow) {
                        var currentFontSize = window.getComputedStyle(elem, null).getPropertyValue('font-size');

                        elem.style.fontSize = parseInt(currentFontSize, 10) - 1 + 'px';

                        isElemOverflow = elem.scrollHeight > elem.offsetHeight;
                    }
                });

                return true;
            },

            /**
             * Sets the text size as big as possible to fit in the element
             * Element has to have a fixed height
             * Element has to have an attribute called `auto-increase-font-size`
             * Working example:
             * <div auto-increase-font-size style="height: 50px;">Lorem ipsum dolor, sit amet</div>
             */
            autoIncreaseFontSize: function() {
                var elems = document.querySelectorAll('[auto-increase-font-size]');

                if (!elems.length) {
                    return false;
                }

                Array.from(elems).forEach(function(elem) {
                    var isElemOverflow = elem.scrollHeight < elem.offsetHeight;

                    while (isElemOverflow) {
                        var currentFontSize = window.getComputedStyle(elem, null).getPropertyValue('font-size');

                        elem.style.fontSize = parseInt(currentFontSize, 10) + 1 + 'px';

                        isElemOverflow = elem.scrollHeight < elem.offsetHeight;
                    }
                });

                return true;
            },

            breakpoints: {
                 /**
                 * Returns the used breakpoint for the viewport width of mobile devices
                 * @returns {Number}
                */
                getMobile: function() {
                    return 767;
                },
                /**
                 * Returns the used breakpoint for the viewport width of tablet devices
                 * @returns {Number}
                 */
                getTablet: function() {
                    return 1000;
                },
                /**
                 * Returns the used breakpoint for the viewport width of desktop devices
                 * @returns {Number}
                 */
                getDesktop: function() {
                    return 1192;
                },
                /**
                 * Returns the used breakpoint for the viewport width of widescreen devices
                 * @returns {Number}
                 */
                getWidescreen: function() {
                    return 1384;
                }
            },

            formatDate: function(date, now) {
                if (typeof now === 'undefined') {
                    var now = moment();
                }

                var diff = now.diff(moment(date), 'days');

                return (diff > 7)
                    ? moment(date).format('Do MMMM')
                    : moment(date).startOf('minute').fromNow();
            },

            members: {
                /**
                 * Returns the desired members list limit
                 * @param {Number} viewLimit - Should receive from custom.xml (%[page.settings.viewLimit.view]%)
                 * @returns {Number}
                 */
                getLimit: function(viewLimit) {
                    var defaultLimit = 12;
                    var limit = parseInt((viewLimit || defaultLimit), 10);

                    return this.isShowPremiumCard() || this.isShowGiftCard() ? limit - 1 : limit;
                },

                /**
                 * Returns the premium card visibility condition
                 * @returns {Boolean}
                 */
                isShowPremiumCard: function() {

                    if (self.options.isPremium || self.options.isAllowedToGetGifts) {
                        return false;
                    }

                    if (self.environment.isSplashPage()) {
                        return false;
                    }

                    return true;
                },

                /**
                 * Returns a condition about the premium member card can be added to list or not
                 * @param {Number} profileIndex - index of the current profile in the members list
                 * @param {Number} profilesLength - length of the members list array
                 * @returns {Boolean}
                 */
                isRenderPremiumCard: function(profileIndex, profilesLength) {
                    if (!this.isShowPremiumCard()) {
                        return false;
                    }
                    // when result is higher than premium block position
                    var isPositionMatch = profileIndex === (this.getPremiumBlockPosition() - 2);

                    // when result is lower than premium block position
                    var hasLessResult = profilesLength <= (this.getPremiumBlockPosition() - 1)
                        && profileIndex === (profilesLength - 1);

                    return Number.isInteger(parseInt(self.options.giftCount, 10)) && (isPositionMatch || hasLessResult);
                },

                /**
                 * Returns a condition about the free gift card can be added to list or not
                 * @param {Number} profileIndex - index of the current profile in the members list
                 * @param {Number} profilesLength - length of the members list array
                 * @returns {Boolean}
                 */
                isRenderFreeGiftCard: function(profileIndex, profilesLength) {
                    if (!this.isShowPremiumCard()) {
                        return false;
                    }
                    // when result is higher than premium block position
                    var isPositionMatch = profileIndex === (this.getPremiumBlockPosition() - 2);

                    // when result is lower than premium block position
                    var hasLessResult = profilesLength <= (this.getPremiumBlockPosition() - 1)
                        && profileIndex === (profilesLength - 1);

                    return !Number.isInteger(parseInt(self.options.giftCount, 10)) && (isPositionMatch || hasLessResult);
                },

                /**
                 * Returns the premium card visibility condition
                 * @returns {Boolean}
                 */
                isShowGiftCard: function() {

                    if (false === self.options.isAllowedToGetGifts) {
                        return false;
                    }

                    if (self.environment.isSplashPage()) {
                        return false;
                    }

                    return true;
                },

                /**
                 * Returns a condition about the premium member card can be added to list or not
                 * @param {Number} profileIndex - index of the current profile in the members list
                 * @param {Number} profilesLength - length of the members list array
                 * @returns {Boolean}
                 */
                isRenderGiftCard: function(profileIndex, profilesLength) {
                    if (!this.isShowGiftCard()) {
                        return false;
                    }
                    // when result is higher than premium block position
                    var isPositionMatch = profileIndex === (this.getGiftBlockPosition() - 2);

                    // when result is lower than premium block position
                    var hasLessResult = profilesLength <= (this.getGiftBlockPosition() - 1)
                        && profileIndex === (profilesLength - 1);

                    return isPositionMatch || hasLessResult;
                },

                /**
                 * Returns the desired position of the premium member card in the members list
                 * @returns {Number}
                 */
                getPremiumBlockPosition: function() {
                    return 4;
                },

                /**
                 * Returns the desired position of the premium member card in the members list
                 * @returns {Number}
                 */
                getGiftBlockPosition: function() {
                    return 4;
                },

                /**
                 * Returns the premium member card template
                 * @returns {String}
                 */
                getPremiumTemplate: function() {
                    return [
                        '<div class="column is-one-third-desktop is-half-tablet margin-bottom--10">',
                            '<a href="/premium">',
                                '<div class="card card-is-premium">',
                                    '<div class="card-content has-text-centered">',
                                        '<div class="card-is-premium-icon">',
                                            '<span class="icon">',
                                                '<i class="fa fa-diamond" aria-hidden="true"></i>',
                                            '</span>',
                                        '</div>',
                                        '<div class="is-text-bolder is-text-5 padding-top-15 premium-text">',
                                            window.application.options.memberList.title,
                                        '</div>',
                                    '</div>',
                                    '<footer class="notification has-text-centered is-text-bolder is-text-6 card-is-premium-button">',
                                        window.application.options.memberList.button,
                                    '</footer>',
                                '</div>',
                            '</a>',
                        '</div>',
                    ].join('');
                },

                getGiftTemplate: function() {
                    return [
                        '<div data-selector="send-free-gift" class="column is-one-third-desktop is-half-tablet margin-bottom--10">',
                            '<div class="card card-is-premium has-text-centered">',
                                '<section class="margin-top-10">',
                                '<span class="icon card-is-premium-gift card-is-premium-gift-icon">',
                                    '<i class="fa fa-gift" aria-hidden="true"></i>',
                                '</span>',
                                '<span class="icon card-is-premium-gift card-is-premium-gift-icon">',
                                    '<i class="fa fa-gift" aria-hidden="true"></i>',
                                '</span>',
                                '<span class="icon card-is-premium-gift card-is-premium-gift-icon">',
                                    '<i class="fa fa-gift" aria-hidden="true"></i>',
                                '</span>',
                                '<div data-selector="send-free-gift-success-msg" style="display: none;" class="is-text-bolder margin-top-20 is-text-5 margin-top-10 has-text-centered gift-text">',
                                    window.application.options.giftCard.success,
                                '</div>',
                                '<div data-selector="send-free-gift-default-msg" class="is-text-bolder margin-top-20 is-text-5 margin-top-10 has-text-centered gift-text">',
                                    window.application.options.giftCard.title,
                                '</div>',
                                '</section>',
                                '<footer data-selector="send-free-gift-button" class="notification has-text-centered is-text-bolder is-text-6 card-is-premium-gift-button cursor-pointer">',
                                    window.application.options.giftCard.button,
                                '</footer>',
                            '</div>',
                        '</div>',
                    ].join('');
                }


            }
        };
    }

    /**
     * Formats the data for deferred ajax responses for Application
     *
     * @param $.Deferred deferred
     * @param object     data
     * @param string     textStatus
     * @param jqXHR      jqXHR
     */
    Application.deferredAjaxResolve = function(
        deferred,
        data,
        textStatus,
        jqXHR
    ) {
        if (typeof data.errors !== 'undefined' && data.errors.length > 0) {
            deferred.reject(data.errors, data.data);
        } else if (typeof data.data !== 'undefined') {
            if (null !== data.data && typeof data.data.fail !== 'undefined' && true === data.data.fail) {
                deferred.reject(false);
            }
            if (null === data.data) {
                deferred.resolve(true);
            } else {
                deferred.resolve(data.data);
            }
        } else if (typeof data.result !== 'undefined') {
            // This is a v2 result!
            if (null === data.result) {
                deferred.resolve(true);
            } else {
                deferred.resolve(data.result);
            }
        } else if (typeof data.fail !== 'undefined' && false === data.fail) {
            deferred.reject(false);
        } else {
            deferred.resolve(false);
        }
    }

    Application.prototype.callAjax = function(controller, action, data, usev2, ajaxUrl)
    {
        var deferred = new $.Deferred();
        if (typeof usev2 === 'undefined') {
            usev2 = false;
        }
        if (typeof ajaxUrl === 'undefined') {
            ajaxUrl = usev2 ? this.options.v2.ajaxUrl : this.options.ajaxUrl;
        }
        ajaxUrl  = ajaxUrl
            .replace('::controller::', controller)
            .replace('::action::', action);
        if (typeof data === 'undefined') {
            data = {};
        }
        this.latestXhr =  $.ajax({
            type : 'POST',
            url  : ajaxUrl,
            data : data
        }).done(function(data, textStatus, jqXHR) {
            Application.deferredAjaxResolve(deferred, data, textStatus, jqXHR);
        }).fail(function(jqXHR, textStatus, errorThrown) {
            deferred.reject(jqXHR, textStatus, errorThrown);
        });
        return deferred.promise();
    }

    //This is useful to run the app on a different port
    Application.prototype.getCurrentPort = function(){
        var current_href = window.location.href;
        var href_arr = current_href.split(/[://]/);
        var protocol = href_arr[0];
        var port = href_arr[4];

        //If port is not defined in URL, use the default port according to the protocol
        if(isNaN(port) && protocol == "http" ){
            port = 80;
        }
        else if(isNaN(port) && protocol == "https" ){
            port = 443;
        }

        return port;
    }    

    Application.prototype.callApi = function(api, controller, action, data, method) {
        var port = this.getCurrentPort();

        var deferred = $.Deferred(),
            ajaxUrl  = '//api.' + window.location.hostname.replace('www.', '') + ':' + port + '/' + api + '/' + controller + '/' + action;

        method = typeof method !== 'undefined' ? method : 'POST';

        $.ajax({
            type : method,
            url  : ajaxUrl,
            data : data,
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true
        }).done(function(data, textStatus, jqXHR) {
            deferred.resolve(data);
        }).fail(function(jqXHR, textStatus, errorThrown) {
            deferred.reject(jqXHR, textStatus, errorThrown);
        });

        return deferred.promise();
    };

    Application.prototype.call = function (method, ajaxUrl, postData, api) {
        var deferred = new $.Deferred();
        var port = this.getCurrentPort();

        ajaxUrl = (api) ? '//api.' + window.location.hostname.replace('www.', '') + ':' + port + ajaxUrl : ajaxUrl;

        $.ajax({
        type : method,
        url  : ajaxUrl,
        data : postData,
        xhrFields: {
            withCredentials: true
        },
            crossDomain: true
        }).done(function(data, textStatus, jqXHR) {
            deferred.resolve(data);
        }).fail(function(jqXHR, textStatus, errorThrown) {
            deferred.reject(jqXHR, textStatus, errorThrown);
        });

        return deferred.promise();
    };

    Application.prototype.callAjaxUpload = function(controller, action, fileElementId, data)
    {
        var deferred = new $.Deferred(),
            ajaxUrl  = this.options.ajaxUrl
                .replace('::controller::', controller)
                .replace('::action::', action),
            fileElementId = $(fileElementId).attr('id');

        $.ajaxFileUpload({
            url: ajaxUrl,
            data: data,
            secureuri: false,
            fileElementId: fileElementId,
            dataType: 'json',
            success: function (response, status, fileName) {
                eval('response = ' + response);
                if ( response.data == null ) {
                    response.data = {
                        originalFile: fileName
                    };
                } else {
                    response.data.originalFile = fileName;
                }
                if (false === response.ok) {
                    deferred.reject(response, '#' + fileElementId);
                } else {
                    deferred.resolve(response, '#' + fileElementId);
                }
            }
        });
        return deferred.promise();
    };

    Application.prototype.getUserAccount = function () {
        return this.call('get', '/json/profile/getAuthenticatedProfile', {}, false);
      };

    function hideNewMsgNotification() {
        window.application.newMsgNotificationProps.$msgNotification.removeClass('is-visible');
    }

    function showNewMsgNotification(eventObj, message) {
        var props = window.application.newMsgNotificationProps;
        var profileSplashPhoto = '/content/' + eventObj.sourceProfile.clientpath + '/splash.jpg';
        var conversationHref = '/conversation/' + eventObj.sourceProfile.id;
        var splashPhotoHref = '/img/nosplash_' + eventObj.sourceProfile.gender + '.png';
        if(!window.emoji) window.emoji = new EmojiConvertor();

        var image = new Image();

        //check if image excist, if not show splash banner
        image.src = profileSplashPhoto;

        image.onload = function() {
            // image did not load
            props.$profileImg.attr('src', profileSplashPhoto);
        };
        image.onerror = function() {
            // image did not load
            props.$profileImg.attr('src', splashPhotoHref);
        };

        props.$profileImg.attr('src', profileSplashPhoto);
        props.$anchor.attr('href', conversationHref);

        if (window.application.isMobile()) {
            // show notification
            props.$msgNotification.addClass('is-visible');

            // set progress bar
            props.mobileProgressBar.set(0);

            // animate progress bar
            props.mobileProgressBar.animate(1);
        } else {
            // reset progress bar
            props.$progress.css('width', '100%');

            // add raw message
            props.$message.html(message);

            // apply emoji
            props.$message.emoji();

            // trim message and replace entities
            props.$message.html( $.trim(props.$message.html().replace(/&lt;/g, '<').replace(/&gt;/g, '>')) );

            // replace <br> with whitespace
            props.$message.find('br').replaceWith(' ');

            // show notification
            props.$msgNotification.addClass('is-visible');

            // reduce progress bar
            props.$progress.animate({
                'width': 0
            }, props.delay);
        }

        setTimeout(hideNewMsgNotification, props.delay);
    }

    Application.prototype.newMsgNotificationProps = {
        $msgNotification: null,
        $progress: null,
        $close: null,
        $profileImg: null,
        $anchor: null,
        $message: null,
        delay: 10000,
        mobileProgressBar: null
    };

    Application.prototype.initNewMsgNotification = function() {
        var props = this.newMsgNotificationProps;

        props.$msgNotification = $('[data-selector="msg-notification"]');
        props.$progress = props.$msgNotification.find('[data-selector="msg-notification-progress-desktop"]');
        props.$close = props.$msgNotification.find('[data-selector="msg-notification-close"]');
        props.$profileImg = props.$msgNotification.find('[data-selector="msg-notification-profile-img"]');
        props.$anchor = props.$msgNotification.find('[data-selector="msg-notification-anchor"]');
        props.$message = props.$msgNotification.find('[data-selector="msg-notification-message"]');

        props.$close.on('click', hideNewMsgNotification);

        props.mobileProgressBar = new ProgressBar.Circle('#msg-notification-progress-mobile', {
            duration: props.delay
        });
    };

    Application.prototype.getEvents = function(refreshRate) {
        var self  = this,
            since = window.storage.get('events', 'since'),
            postData = {},
            profile  = {},
            tmpl  = '',
            html  = '';

        if (since) {
            postData.since = since.substr(0, since.length - 3) + '00';
        }

        this.callApi('dating', 'event', 'load', postData).then(
            function(response) {
                var latest   = response[response.length - 1] || {},
                    lastDate = latest.triggerDate || since,
                    eventObj = null;

                for (var i = 0, len = response.length; i < len; i++) {
                    var eventName = response[i].event;

                    if (eventName === 'messaging.new_message') {
                        if (window.application.isMobile()) {
                            showNewMsgNotification(response[i]);
                        } else {
                            var postData = {};

                            postData.profile_id = response[i].sourceProfile.id;
                            postData.limit      = 1;
                            postData.markAsRead = false;

                            eventObj = response[i];

                            window.application.callAjax('messagebox', 'list', postData, true)
                                .then(function(messageboxResponse) {
                                    var latestMessage = messageboxResponse.messages[messageboxResponse.messages.length - 1].body;

                                    showNewMsgNotification(eventObj, latestMessage);
                                });
                        }
                    }
                }

                window.setTimeout(function() {
                    self.getEvents(refreshRate);
                }, refreshRate);

                if (lastDate !== null) {
                    window.storage.set('events', 'since', lastDate);
                }
            },
            function(error) {

            }
        );
    };

    Application.prototype.renderNotification = function(event, profile) {
        var $notification = $('[data-notification]'),
            tmpl          = Hogan.compile($('[data-notificationtpl="' + event + '"]').html());

        profile.profilePhoto = '/content/' + profile.clientpath + '/splash.jpg';

        if ($notification.is(':visible')) {
            $('body').prepend(tmpl.render(profile));
            $('[data-notification]').css({
                'margin-top' : '-60px'
            });
            $notification.remove();
        } else {
            $('body').prepend(tmpl.render(profile));

            $('[data-notification]').animate(
                {
                    'margin-top' : '+=60px'
                },
                500
            );
        }

        $('[data-closenotification]', '[data-notification]').click(function() {
            $(this).closest('[data-notification]').animate(
                {
                    'margin-top' : '-=60px'
                },
                500,
                function() {
                    $(this).remove();
                }
            );
        });

        profile.data = profile;
        tmpl = Hogan.compile($('#template-results').html());
        html = tmpl.render(profile);
        $('h2', '[data-sidebar="' + event + '"]').after(html);

        if ($('img', '[data-sidebar="' + event + '"]').length > 9) {
            $('a', '[data-sidebar="' + event + '"]').not('.more').last().remove();
        }
    };

    Application.prototype.updateEventCounters = function(counterData) {
        for (var i in counterData) {
            if (counterData[i] > 0 && i !== 'credits') {
                $('[data-counter="' + i + '"]').text(counterData[i]).show();
            } else {
                $('[data-counter="' + i + '"]').hide();
            }
        }
    };

    Application.prototype.alert = function(title, message) {
        if (window.popup === undefined || this.isMobile() === true) {
            alert(message);
            return;
        }

        return window.popup.render('alert', {title : title, message : message});
    };

    Application.prototype.toProfile = function(clientId)
    {
        if ($('body').hasClass('authenticated') === true) {
            window.location.href = '/profile/' + clientId;
            return;
        }

        if (clientId === undefined || clientId === null || clientId.length === 0) {
            return window.popup.render('register', {url : { query : window.location.search }});
        } else {
            window.search.getByClientId(clientId, true).then(
                function(profile) {
                    return window.popup.render('register', {profile : profile, url : { query : window.location.search }});
                },
                function() {
                    return window.popup.render('register', {url : { query : window.location.search }});
                }
            );
        }
    };

    Application.prototype.isMobile = function() {
        return $(window).width() < 768;
    };

      Application.prototype.loadTemplate = function (uri) {
          var deferred = new $.Deferred(),
              content = window.storage.get('tpl', uri);

          if (content !== null) {
              window.setTimeout(
                  function ()
                  {
                      deferred.resolve(content);
                  },
                  1
              );

              return deferred.promise();
          }

          $.ajax(
              {
                  url     : uri,
                  dataType: 'text'
              }
          ).then(
              function (content)
              {
//                  window.storage.set('tpl', uri, content);
                  deferred.resolve(content);
              },
              deferred.reject
          );

          return deferred.promise();
      };

    /**
     * Throws an error prefixed with the Application name
     *
     * @param string error
     */
    Application.prototype.throwError = function(error) {
        throw 'Application ERROR: ' + error;
    };

    Application.prototype.stickyDisclaimer = function() {
        var htmlH  = $('html').outerHeight(),
            headerH  = $('.header').outerHeight(),
            menuH  = $('.menu-bar').outerHeight();

        $('.content').css('min-height', htmlH - headerH - menuH);
    };

    Application.prototype.isTouchDevice = function() {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (e) {
            return false;
        }
    };

      Application.prototype.showExoBanner = function(exoClickData) {
          //today
          var today = new Date();

          //get the actual date from the var
          exoClickData.approvedDate = new Date(exoClickData.approvedDate.date.substring(0,10));

          //compare the approved date + 7 days with today and if its smaller load the banners
          if (exoClickData.approvedDate.setDate(exoClickData.approvedDate.getDate() + 7) < today.setDate(today.getDate())) {
              var s = document.createElement('script');
              s.src = 'https://ads.exosrv.com/ads.js';
              document.head.appendChild(s);
              s.onload = function() {
                  if ("function" === typeof MobileDetect) {
                      var detector = (new MobileDetect(window.navigator.userAgent));
                      var makeIframe = document.createElement("iframe");
                      if (detector.mobile()) {
                          var getRef = document.getElementById("mob_shownat");
                          getRef.style.display = "block";
                          getRef.innerHTML = '<iframe src="//ads.exosrv.com/iframe.php?idzone=' +exoClickData.mobileFooter + '&size=300x250" width="300" height="250" scrolling="no" marginwidth="0" marginheight="0" frameborder="0"></iframe>';

                          var getRefHeader = document.getElementById("mob_shownat_header");
                          getRefHeader.style.display = "block";
                          getRefHeader.innerHTML = '<iframe src="//ads.exosrv.com/iframe.php?idzone='+ exoClickData.mobileHeader +'&size=300x100" width="300" height="100" scrolling="no" marginwidth="0" marginheight="0" frameborder="0"></iframe>';
                      } else {
                          var getRef = document.getElementById("desk_shownat");
                          getRef.style.display = "block";
                          getRef.innerHTML =
                              '<iframe src="//ads.exosrv.com/iframe.php?idzone='+ exoClickData.footerA +'&size=300x250" width="300" height="250" scrolling="no" marginwidth="0" marginheight="0" frameborder="0"></iframe>'
                              + '<iframe src="//ads.exosrv.com/iframe.php?idzone='+ exoClickData.footerB +'&size=300x250" width="300" height="250" scrolling="no" marginwidth="0" marginheight="0" frameborder="0"></iframe>'
                              + '<iframe src="//ads.exosrv.com/iframe.php?idzone=' + exoClickData.footerC +'&size=300x250" width="300" height="250" scrolling="no" marginwidth="0" marginheight="0" frameborder="0"></iframe>'
                      }
                  }
              };
          }
      };

    Application.prototype.getAge = function(dateString) {
        var today     = new Date(),
            dateSplit = dateString.split('T')[0].split('-'),
            birthDate = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]),
            age       = today.getFullYear() - birthDate.getFullYear(),
            m         = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    Application.prototype.getDate = function(date) {
        var itemDate  = new Date(String(date).split(' ')[0]),
            itemDay   = itemDate.getDate();
            itemMonth = itemDate.getMonth() + 1;
            itemYear  = itemDate.getFullYear();

            return this.padLeft(itemDay, 2, '0') + '-' + this.padLeft(itemMonth, 2, '0') + '-' + itemYear;
    };

    Application.prototype.getTime = function(date) {
        var itemDate  = new Date(date);

        return itemDate.getHours() + ':' + this.padLeft(itemDate.getMinutes().toString(), 2, '0');
    };

    Application.prototype.convertDate = function(date) {
        return date.substr(0, date.length - 3).replace(' ', 'T') + 'Z';
    };

    Application.prototype.pad = function(orgString, pad_length, pad_string, pad_type) {
        var string   = orgString.toString(),
            half     = null,
            delta    = pad_length - string.length,
            repeater = function(string, length) {
                var collect = '',
                    i       = null;

                while (collect.length < length) {
                    collect += string;
                }

                collect = collect.substr(0, length);

                return collect;
            };

        // Invalid string return original
        if (pad_length === undefined || pad_length <= 0) {
            return string;
        }

        // String long enough
        if (delta < 0) {
            return string;
        }

        // Set default pad_string
        pad_string = pad_string || ' ';

        // Set correct PAD TYPE
        switch (pad_type) {
            case 'STR_PAD_LEFT':
                string = repeater(pad_string, delta) + string;
                break;
            case 'STR_PAD_BOTH':
                half = repeater(pad_string, Math.ceil(delta / 2));
                string = half + string + half;
                break;
            case 'STR_PAD_RIGHT':
            default:
                string += repeater(pad_string, delta);
                break;
        }

        return string;
    };

    Application.prototype.padLeft = function(string, pad_length, pad_string) {
        return this.pad(string, pad_length, pad_string, 'STR_PAD_LEFT');
    };

    Application.prototype.padRight = function(string, pad_length, pad_string) {
        return this.pad(string, pad_length, pad_string, 'STR_PAD_RIGHT');
    };

    Application.prototype.padBoth = function(string, pad_length, pad_string) {
        return this.pad(string, pad_length, pad_string, 'STR_PAD_BOTH');
    };

    Application.prototype.svgCheck = function() {
        if(!document.createElementNS('http://www.w3.org/2000/svg','svg').createSVGRect) {
            $('[data-svg]').each(function() {
                $(this).attr('src', $(this).attr('src').split('.')[0] + '.png');
            });

            $('body').addClass('no-svg');
        }
    };

    Application.prototype.formatMoney = function (number) {
        return number.toLocaleString(window.application.options.paymentVariables.countryLanguageCode, {
            style: 'currency',
            currency: window.application.options.paymentVariables.currency
        });
    };

    return Application;

  })();

  this.Application = Application;
}).call(this);
