/**
 * imageService.js
 *
 */

/**
 *
 * @type {{getInstance}}
 */
var imageService = (function () {

    // Instance stores a reference to the Singleton
    var instance;

    function init() {

        "use strict";

        // Private variables and functions
        var blurCode = window.application.options.blurCode;
        var isPremium = window.application.options.isPremium;

        var unblurImage = function (imgUrl) {
            return imgUrl.replace(blurCode, "default");
        };

        // Public API
        return {
            unblurImage : unblurImage
        };

    }

    return {

        // Get the Singleton instance if one exists
        // or create one if it doesn't
        getInstance: function () {

            if (!instance) {
                instance = init();
            }

            return instance;
        }

    };

})();

